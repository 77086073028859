import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { Draft } from "immer";
import {
  ContainerDataType,
  DataType,
  IdentityDataType,
  isDtUser,
  UnionDataType,
} from "sutro-common";
import type { DataTypeEdge } from "sutro-common/edges/data-type-edge";
import { isDataTypeSomeEdge } from "sutro-common/edges/is-edge-some-edge";

import { ensureDefinitionIncludesCreatorEdge as updateDefinitionToEnsureCreatorEdgeExists } from "../definition-updaters/index.js";
// Ensure that if anybody can add or remove AND either the container or contained DT isn't user, then user has some of what is contained, and what is contained belongs to a user
export const ensureUserHasSomeDtAndDtBelongsToUserIfNecessary = ({
  edge,
  dataType,
  containedDt,
  draftDataTypes,
  changeTracker,
}: {
  edge: DataTypeEdge;
  dataType: ContainerDataType;
  containedDt: Exclude<DataType, UnionDataType | IdentityDataType>;
  draftDataTypes: Draft<DataType[]>;
  changeTracker: ChangeTracker;
}): void => {
  if (
    isDataTypeSomeEdge(edge) &&
    isDtUser(containedDt) === false &&
    isDtUser(dataType) === false
  ) {
    updateDefinitionToEnsureCreatorEdgeExists({
      draftDataTypes,
      edgeFromContainerToContained: edge,
      containerDt: dataType,
      containedDt,
      reason: `${dataType.name} has SOME ${containedDt.name} and whoCanAddRemove.type === ANYBODY or LIST_MEMBERS_ONLY`,
      changeTracker,
    });
  }
};
