import { Draft } from "immer";
import { EFFECT_TYPES, SerializedAction } from "sutro-common";

// Since CHARGE_USER and SET_UP_SUBSCRIPTION_PAYMENTS move money to people, these imply receiving payments
export const doesDefinitionIncludeReceivingPayments = ({
  actions,
}: {
  actions: Draft<SerializedAction>[];
}) => {
  const { actionToSetUpReceivingPayments } =
    getSetUpReceivingPaymentsActionAndEffect(actions);

  if (actionToSetUpReceivingPayments !== undefined) {
    return true;
  }

  /** @todo:  look in the onSuccess effects too */
  return actions.some((a) =>
    a.effectInstructions.some(
      (eI) =>
        eI.effectType === EFFECT_TYPES.CHARGE_USER ||
        eI.effectType === EFFECT_TYPES.SET_UP_SUBSCRIPTION_PAYMENTS
    )
  );
};

export const getSetUpReceivingPaymentsActionAndEffect = (
  actions: Draft<SerializedAction[]>
) => {
  const actionToSetUpReceivingPayments = actions.find((a) =>
    a.effectInstructions.some(
      (i) => i.effectType === EFFECT_TYPES.REGISTER_STRIPE_EXPRESS_ACCOUNT
    )
  );
  const effectForSetUpReceivingPayments =
    actionToSetUpReceivingPayments?.effectInstructions.find(
      (i) => i.effectType === EFFECT_TYPES.REGISTER_STRIPE_EXPRESS_ACCOUNT
    );

  return {
    actionToSetUpReceivingPayments,
    effectForSetUpReceivingPayments,
  };
};
