import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { createEmptyDt } from "@sutro/studio2-quarantine/definitions/definition-updaters/create-empty-dt";
import { UpdateInstanceWithSetToUpdateVariableParams } from "@sutro/studio2-quarantine/definitions/definition-updaters/update-instance-with-set-to-update-variable";
import { getInverseFieldName } from "@sutro/studio2-quarantine/lib/dt-manipulation-helpers";
import { doesObjMatchFeatureTemplateAndIdempotencyKey } from "@sutro/studio2-quarantine/lib/feature-template-helpers";
import { DefinitionTransactionalUpdater } from "@sutro/studio2-quarantine/types/definition-transactional-updater";
import { UpdateDefinitionWithFeatureTemplateStatePatch } from "@sutro/studio2-quarantine/types/update-definition-with-feature-template-state-patch";
import { Draft } from "immer";
import pluralize, { singular } from "pluralize";
import {
  $TSFixMe,
  ContainerDataType,
  DataType,
  Definition,
  DraftOrRaw,
  DT_TYPES,
  DtId,
  getDt,
  getUserDt,
  IdentityDataType,
  InteractionAnnotationPatch,
  isDtContainer,
  isDtIdentity,
  isDtUnion,
  newEdgeId,
  objectAssignWithNullsDeleted,
  PrimitiveDataType,
  primitivesAndMetaCommon,
  removeLeadingPotentialAdjective,
  SerializedAction,
  SerializedCreateEffectInstruction,
  SerializedEffectInstruction,
  SerializedFieldInstruction,
  SerializedPathValueVariable,
  SerializedToggleIntoSetEffectInstruction,
  SerializedUpdateEffectInstruction,
  SutroError,
  toStartOfSentenceCase,
  UnionDataType,
} from "sutro-common";
import { APP_ROOT_DT_ID, USER_DT_ID } from "sutro-common/dt-id";
import type {
  DataTypeEdge,
  DataTypeEdgeIdAndDirection,
} from "sutro-common/edges/data-type-edge";
import {
  DATA_TYPE_EDGE_DIRECTION,
  EDGE_QUANTITY_TYPES,
  MODIFIER_CAN_ADD_REMOVE_TYPES,
  WHO_CAN_ADD_REMOVE_TYPES,
  WHO_CAN_SEE_TYPES,
} from "sutro-common/edges/data-type-edge";
import { getEdgeFromDtsByEdgeId } from "sutro-common/edges/get-edge-from-dts-by-edge-id";
import { getEdgesAndUnionEdges } from "sutro-common/edges/get-edges";
import { getReverseEdge } from "sutro-common/edges/get-reverse-edge";
import { isCreatorEdge } from "sutro-common/edges/is-creator-edge";
import { isDataTypeSomeEdge } from "sutro-common/edges/is-edge-some-edge";
import { IdempotencyKeyAndFeatureTemplateKey } from "sutro-common/feature-templates";
import { TEXT_LENGTH } from "sutro-common/primitive-text";

import { getDtAndActionModifiers } from "~/lib/definitions/definition-updaters/get-dt-and-action-modifiers";
import { getPatchForUpdatingDefinitionInStateAfterValidating } from "~/lib/definitions/definition-updaters/get-patch-for-updating-definition-in-state-after-validating";

export {
  getDtAndActionModifiers,
  getPatchForUpdatingDefinitionInStateAfterValidating,
};

export type DraftDefinitionAndNextIdsContainer = {
  draftDefinition: Draft<Definition>;
};

export const getUpdatedDefinitionAfterTransaction = ({
  fnThatTakesUpdaters,
  definition,
}: {
  fnThatTakesUpdaters: DefinitionTransactionalUpdater;
  definition: Definition;
}): Definition => {
  // Make a shallow copy so we can reassign to it within onChangeDefinition without mutating the original
  const definitionCopy: Definition = {
    ...definition,
  };

  const onChangeDefinition = ({
    newDefinition,
  }: {
    newDefinition: Definition;
  }) => {
    definitionCopy.dataTypes = newDefinition.dataTypes;
    definitionCopy.actions = newDefinition.actions;
    // I'm not totally sure the outside world always passes through featureTemplatesData so hold on to the old version if we didn't get given a new one
    definitionCopy.featureTemplatesData =
      newDefinition.featureTemplatesData ?? definitionCopy.featureTemplatesData;
  };

  const { dtModifiers, actionModifiers } = getDtAndActionModifiers({
    definition: definitionCopy,
    onChangeDefinition,
  });

  // This is a tiny bit hacky, some definition updaters have some of their logic actually done by the validation code
  // (e.g. adding converse creator edges), so this allows users of updateDefinition to request a validation
  const forceValidate = () => {
    const { patchForUpdatingDefinition } =
      getPatchForUpdatingDefinitionInStateAfterValidating({
        newDefinition: definitionCopy,
        isInitializing: false,
      }) ?? {};
    const definitionPatch = {
      dataTypes: patchForUpdatingDefinition?.dataTypes ?? [],
      actions: patchForUpdatingDefinition?.actions ?? [],
    };
    onChangeDefinition({
      newDefinition: {
        ...definitionCopy,
        ...definitionPatch,
      },
    });
  };

  const updateDefinitionWithFeatureTemplateStatePatch: UpdateDefinitionWithFeatureTemplateStatePatch =
    ({
      newFeatureTemplateState,
      newFrameworkMetaPatch,
      featureTemplateKey,
    }) => {
      const newDefinition = getDefinitionWithFeatureTemplatePatchApplied({
        prevDefinition: definitionCopy,
        newFeatureTemplateState,
        newFrameworkMetaPatch,
        featureTemplateKey,
      });
      onChangeDefinition({
        newDefinition,
      });
    };

  fnThatTakesUpdaters({
    dtModifiers,
    actionModifiers,
    definition: definitionCopy,
    forceValidate,
    updateDefinitionWithFeatureTemplateStatePatch,
  });

  return definitionCopy;
};

export const setFieldName = ({
  draftDt,
  edgeIdAndDirection,
  newName,
}: {
  draftDt: Draft<DataType>;
  edgeIdAndDirection: DataTypeEdgeIdAndDirection;
  newName: string;
}) => {
  const edge = getDraftEdge({ draftDt, edgeIdAndDirection });
  edge.fieldName = newName;

  // If user updates the name of the edge, it should no longer be generated
  if (edge?.pluginData?.namingMeta?.shouldUseGeneratedName !== undefined) {
    delete edge.pluginData.namingMeta.shouldUseGeneratedName;
    if (Object.keys(edge.pluginData.namingMeta).length === 0) {
      delete edge.pluginData.namingMeta;
      if (Object.keys(edge.pluginData).length === 0) {
        delete edge.pluginData;
      }
    }
  }
};

export const setEdgeQuantity = ({
  draftDt,
  edgeIdAndDirection: { edgeId, direction },
  newEdgeQuantity,
}: {
  draftDt:
    | Draft<ContainerDataType>
    | Draft<UnionDataType>
    | Draft<PrimitiveDataType>
    | Draft<IdentityDataType>;
  edgeIdAndDirection: DataTypeEdgeIdAndDirection;
  newEdgeQuantity: EDGE_QUANTITY_TYPES;
}) => {
  const edge = draftDt.edges.find(
    (e) => e.edgeId === edgeId && e.direction === direction
  );
  if (edge === undefined) {
    return;
  }

  edge.quantity = newEdgeQuantity;

  if (newEdgeQuantity === EDGE_QUANTITY_TYPES.SINGLE) {
    // @ts-expect-error TS2339 - we're changing types here, so TS get's unhappy
    delete edge.whoCanAddRemove;
    delete edge.whoCanSee;
    // @ts-expect-error TS2339 - we're changing types here, so TS get's unhappy
    delete edge.modifierCanAddRemove;
  } else if (newEdgeQuantity === EDGE_QUANTITY_TYPES.SOME) {
    Object.assign(edge, {
      whoCanAddRemove: { type: WHO_CAN_ADD_REMOVE_TYPES.OWNER },
      whoCanSee: { type: WHO_CAN_SEE_TYPES.ANYBODY },
      modifierCanAddRemove: { type: MODIFIER_CAN_ADD_REMOVE_TYPES.OWN_ONLY },
    });
  }
};

export const setHasInteractionAnnotation = ({
  draftDataTypes,
  draftDt,
  edgeIdAndDirection,
  newInteractionAnnotation,
}: {
  draftDataTypes: Draft<DataType[]>;
  draftDt: Draft<ContainerDataType> | Draft<UnionDataType>;
  edgeIdAndDirection: DataTypeEdgeIdAndDirection;
  newInteractionAnnotation: InteractionAnnotationPatch;
}) => {
  const draftHasEdge = getDraftEdge({ draftDt, edgeIdAndDirection });
  objectAssignWithNullsDeleted(draftHasEdge, newInteractionAnnotation);
  if (
    newInteractionAnnotation.whoCanAddRemove?.type ===
    WHO_CAN_ADD_REMOVE_TYPES.NOBODY
  ) {
    const reverseEdge = getReverseEdge({
      edge: draftHasEdge,
      dataTypes: draftDataTypes,
    });
    if (reverseEdge !== undefined && isDataTypeSomeEdge(reverseEdge)) {
      reverseEdge.whoCanAddRemove = {
        ...reverseEdge.whoCanAddRemove,
        type: WHO_CAN_ADD_REMOVE_TYPES.NOBODY,
      };
    }
  }
};

export const ensureDefinitionIncludesCreatorEdge = ({
  draftDataTypes,

  containedDt,
  edgeFromContainerToContained,
  reason,
  changeTracker,
}: {
  draftDataTypes: Draft<DataType[]>;
  containerDt: ContainerDataType;
  containedDt: ContainerDataType | UnionDataType | PrimitiveDataType;
  edgeFromContainerToContained: DataTypeEdge;
  reason: string;
  changeTracker: ChangeTracker;
}) => {
  let didFix = false;
  const userDt = getUserDt(draftDataTypes);
  // console.log(JSON.parse(JSON.stringify({ userDt, containedDt })));
  // throw "stop";
  const creatorEdge = userDt.edges.find(
    (edge) => edge.relatedDtId === containedDt.id && isCreatorEdge(edge)
  );
  if (creatorEdge === undefined) {
    // Add the creator edge
    userDt.edges.push({
      direction: DATA_TYPE_EDGE_DIRECTION.has,
      relatedDtId: containedDt.id,
      fieldName: pluralize(containedDt.name),
      quantity: EDGE_QUANTITY_TYPES.SOME,
      // Heuristically, if the edge was WHO_CAN_ADD_REMOVE_TYPES.NOBODY, make the creator edge this too
      whoCanAddRemove: {
        type:
          isDataTypeSomeEdge(edgeFromContainerToContained) &&
          edgeFromContainerToContained.whoCanAddRemove?.type ===
            WHO_CAN_ADD_REMOVE_TYPES.NOBODY
            ? WHO_CAN_ADD_REMOVE_TYPES.NOBODY
            : WHO_CAN_ADD_REMOVE_TYPES.OWNER,
      },
      whoCanSee: { type: WHO_CAN_SEE_TYPES.ANYBODY },
      modifierCanAddRemove: { type: MODIFIER_CAN_ADD_REMOVE_TYPES.OWN_ONLY },
      edgeId: newEdgeId(),
      pluginData: {
        creationMeta: {
          isCreatorEdge: true,
        },
      },
    });
    didFix = true;
    changeTracker.trackChange(
      `0: Added edge User has SOME ${containedDt.name}, ${containedDt.name} belongs to SINGLE User because ${reason}`
    );
    // alert(
    //   `Added ${pluralize(
    //     containedDt.name
    //   )} to User (required for internal reasons)`
    // );
  }
  return { didFix };
};

export const addBelongsToEdge = ({
  shouldBelongToId,
  draftDt,
  forDraftEdge,
  draftDataTypes,
}: {
  shouldBelongToId: DtId;
  draftDt:
    | Draft<ContainerDataType>
    | Draft<UnionDataType>
    | Draft<PrimitiveDataType>;
  forDraftEdge: Draft<DataTypeEdge>;
  draftDataTypes: Draft<DataType[]>;
}) => {
  // If can add anybodys, then each child must be able to belong to multiple.
  const newEdgeQuantity =
    shouldBelongToId !== APP_ROOT_DT_ID &&
    forDraftEdge.quantity === EDGE_QUANTITY_TYPES.SOME &&
    (forDraftEdge.modifierCanAddRemove?.type ===
      MODIFIER_CAN_ADD_REMOVE_TYPES.ANYBODYS ||
      (forDraftEdge.modifierCanAddRemove?.type ===
        MODIFIER_CAN_ADD_REMOVE_TYPES.OWN_ONLY &&
        forDraftEdge.relatedDtId === USER_DT_ID))
      ? EDGE_QUANTITY_TYPES.SOME
      : EDGE_QUANTITY_TYPES.SINGLE;

  draftDt.edges.push({
    relatedDtId: shouldBelongToId,
    quantity: EDGE_QUANTITY_TYPES.SINGLE,
    edgeId: forDraftEdge.edgeId,
    direction: DATA_TYPE_EDGE_DIRECTION.belongsTo,
    fieldName: getInverseFieldName({
      edgeId: forDraftEdge.edgeId,
      fieldName: forDraftEdge.fieldName,
      originalEdgeFromDt: getDt(draftDataTypes, shouldBelongToId),
      originalEdgeToDt: draftDt,
      dts: draftDataTypes,
      belongsDirectionQuantity: newEdgeQuantity,
      isEdgeCreatorEdge: isCreatorEdge(forDraftEdge),
    }),
  });

  setEdgeQuantity({
    draftDt,
    edgeIdAndDirection: {
      edgeId: forDraftEdge.edgeId,
      direction: DATA_TYPE_EDGE_DIRECTION.belongsTo,
    },
    newEdgeQuantity,
  });
};

export const getTypeNameFromFieldName = (fieldName: string) => {
  return singular(
    toStartOfSentenceCase(removeLeadingPotentialAdjective(fieldName))
  );
};

const getDtForIdempotencyKey = (
  idempotencyKeyAndFeatureTemplateKey:
    | IdempotencyKeyAndFeatureTemplateKey
    | undefined,
  draftDataTypes: DataType[]
) => {
  if (idempotencyKeyAndFeatureTemplateKey === undefined) {
    return undefined;
  }
  return draftDataTypes.find((dt) =>
    doesObjMatchFeatureTemplateAndIdempotencyKey({
      obj: dt,
      idempotencyKeyAndFeatureTemplateKey,
    })
  );
};

export const getOrCreateDtForEdgeWithIdempotencyKey = (
  { draftDefinition }: DraftDefinitionAndNextIdsContainer,
  {
    type,
    newTypeName,
    idempotencyKeyAndFeatureTemplateKey,
    dontHeuristicallyCreateEdges,
  }: {
    type:
      | DT_TYPES.CONTAINER
      | DT_TYPES.UNION
      | DT_TYPES.ENUM
      | DT_TYPES.IDENTITY;
    newTypeName: string;
    idempotencyKeyAndFeatureTemplateKey?: IdempotencyKeyAndFeatureTemplateKey;
    dontHeuristicallyCreateEdges?: boolean;
  }
): DataType => {
  const { dataTypes: draftDataTypes } = draftDefinition;

  const preExistingDt = getDtForIdempotencyKey(
    idempotencyKeyAndFeatureTemplateKey,
    draftDataTypes
  );

  if (preExistingDt !== undefined) {
    return preExistingDt;
  }

  const newDt = createEmptyDt({ type, newTypeName });

  if (idempotencyKeyAndFeatureTemplateKey !== undefined) {
    newDt.featureTemplateMeta = [idempotencyKeyAndFeatureTemplateKey];
  }

  // Note we don't explicitly add the belongsTo edge here because it will be added by the invariants system

  if (!isDtIdentity(newDt)) {
    if (isDtContainer(newDt) && dontHeuristicallyCreateEdges !== true) {
      const dtIsPostLike = ["Post", "Comment"].includes(newDt.name);
      const dtShouldHaveTitle = ["Book", "Movie"].includes(newDt.name);

      // Default to all new containers having a name
      newDt.edges = [
        {
          fieldName: dtIsPostLike
            ? "Body"
            : dtShouldHaveTitle
              ? "Title"
              : "Name",
          quantity: EDGE_QUANTITY_TYPES.SINGLE,
          relatedDtId: primitivesAndMetaCommon.TEXT.id,
          // "body" and "description" which is right now set during fieldName edit
          textLength: dtIsPostLike ? TEXT_LENGTH.long : TEXT_LENGTH.medium,
          edgeId: newEdgeId(),
          direction: DATA_TYPE_EDGE_DIRECTION.has,
        },
      ];

      if (dtIsPostLike) {
        newDt.edges.push({
          quantity: EDGE_QUANTITY_TYPES.SINGLE,
          fieldName: "Posted at",
          relatedDtId: primitivesAndMetaCommon.DATE_TIME.id,
          edgeId: newEdgeId(),
          direction: DATA_TYPE_EDGE_DIRECTION.has,
        });
      }
    } else if (isDtUnion(newDt)) {
      newDt.containsUnionEdges = [];
    }
  }

  draftDataTypes.push(newDt);
  return newDt;
};

export const changeDraftVariablePathEdgeAtIndex = ({
  variable,
  pathIndex,
  newEdgeIdAndDirection,
}: {
  variable: DraftOrRaw<SerializedPathValueVariable>;
  pathIndex: number;
  newEdgeIdAndDirection: DataTypeEdgeIdAndDirection;
}) => {
  if (pathIndex <= variable.directionalEdgeIdPath.length) {
    variable.directionalEdgeIdPath = variable.directionalEdgeIdPath.slice(
      0,
      pathIndex
    );
    variable.directionalEdgeIdPath.push(newEdgeIdAndDirection);
  } else {
    variable.directionalEdgeIdPath.push(newEdgeIdAndDirection);
  }
};

export type NextStepData = {
  newOrPreExistingDt?: DataType;
};

export const getDraftEdge = ({
  edgeIdAndDirection,
  draftDefinition,
  draftDt,
}: {
  edgeIdAndDirection: DataTypeEdgeIdAndDirection;
} & (
  | {
      draftDefinition?: Draft<Definition>;
      draftDt?: undefined;
    }
  | {
      draftDt?:
        | Draft<ContainerDataType>
        | Draft<UnionDataType>
        | Draft<PrimitiveDataType>
        | Draft<IdentityDataType>;
      draftDefinition?: $TSFixMe;
    }
)): Draft<DataTypeEdge> => {
  if (draftDt !== undefined) {
    const unionEdge = getEdgesAndUnionEdges(draftDt).find(
      (edge) =>
        edge.edgeId === edgeIdAndDirection.edgeId &&
        edge.direction === edgeIdAndDirection.direction
    );
    if (unionEdge !== undefined) {
      return unionEdge;
    }
  } else {
    const dtEdge = getEdgeFromDtsByEdgeId({
      dataTypes: draftDefinition.dataTypes,
      edgeIdAndDirection,
    });
    if (dtEdge !== undefined) {
      return dtEdge;
    }
  }
  throw new SutroError("Could not find edge", {
    context: {
      ...edgeIdAndDirection,
      draftDtId: draftDt?.id ?? "undefined",
      draftDtType: draftDt?.type ?? "undefined",
    },
  });
};

export const getDraftAction = ({
  draftDefinition,
  action,
}: {
  draftDefinition: Draft<Definition>;
  action: DraftOrRaw<SerializedAction>;
}) => draftDefinition.actions.find((a) => a.actionId === action.actionId);

export const getDraftEffectObj = ({
  draftDefinition,
  effect,
  action,
}: {
  draftDefinition: Draft<Definition>;
  effect: DraftOrRaw<SerializedEffectInstruction>;
  action: DraftOrRaw<SerializedAction>;
}) => {
  const draftAction = getDraftAction({ draftDefinition, action });
  if (draftAction === undefined) {
    throw new SutroError("Could not find action", {
      context: {
        actionId: action.actionId,
        effectId: effect.effectId,
      },
    });
  }
  return {
    parentObj: draftAction.effectInstructions,
    keyToDraftEffect: draftAction.effectInstructions.findIndex(
      (candidateEffect) => candidateEffect.effectId === effect.effectId
    ),
    draftEffect: draftAction.effectInstructions.find(
      (candidateEffect) => candidateEffect.effectId === effect.effectId
    ),
  };
};

export const getDraftInstruction = ({
  draftDefinition,
  instruction,
  effect,
  action,
}: {
  draftDefinition: Draft<Definition>;
  instruction: DraftOrRaw<SerializedFieldInstruction>;
  // Note this doesn't actually have to be an effect with draftInstructions, so long as it's ID matches an effect with
  // draftInstructions
  effect: DraftOrRaw<SerializedEffectInstruction>;
  action: DraftOrRaw<SerializedAction>;
}) => {
  const { draftEffect } = getDraftEffectObj({
    draftDefinition,
    effect,
    action,
  }) as {
    draftEffect:
      | Draft<SerializedCreateEffectInstruction>
      | Draft<SerializedUpdateEffectInstruction>;
  };
  return draftEffect.fieldInstructions.find((candidateFieldInstruction) =>
    areFieldInstructionsForSameEdge({
      fieldInstructionA: candidateFieldInstruction,
      fieldInstructionB: instruction,
    })
  );
};

const areFieldInstructionsForSameEdge = ({
  fieldInstructionA,
  fieldInstructionB,
}: {
  fieldInstructionA: SerializedFieldInstruction;
  fieldInstructionB: SerializedFieldInstruction;
}) => {
  let fieldInstructionAEdgeId = fieldInstructionA.forEdgeId;
  let fieldInstructionAEdgeDirection = fieldInstructionA.forEdgeDirection;
  if (fieldInstructionAEdgeId === undefined) {
    fieldInstructionAEdgeId = fieldInstructionA.forEdge?.edgeId;
    fieldInstructionAEdgeDirection = fieldInstructionA.forEdge?.direction;
  }
  let fieldInstructionBEdgeId = fieldInstructionB.forEdgeId;
  let fieldInstructionBEdgeDirection = fieldInstructionB.forEdgeDirection;
  if (fieldInstructionBEdgeId === undefined) {
    fieldInstructionBEdgeId = fieldInstructionB.forEdge?.edgeId;
    fieldInstructionBEdgeDirection = fieldInstructionB.forEdge?.direction;
  }
  return (
    fieldInstructionAEdgeId === fieldInstructionBEdgeId &&
    fieldInstructionAEdgeDirection === fieldInstructionBEdgeDirection
  );
};

export const afterUpdateInstanceToAddToSetVariable = (
  { draftDefinition }: DraftDefinitionAndNextIdsContainer,
  { action, effect, newVariable }: UpdateInstanceWithSetToUpdateVariableParams
) => {
  const draftEffect = getDraftEffectObj({
    draftDefinition,
    effect,
    action,
  }).draftEffect as Draft<SerializedToggleIntoSetEffectInstruction>;
  draftEffect.instanceToAddToSetVariable = newVariable;
};

export const getDefinitionWithFeatureTemplatePatchApplied = ({
  prevDefinition,
  newFeatureTemplateState,
  newFrameworkMetaPatch,
  featureTemplateKey,
}: {
  prevDefinition: Definition;
  newFeatureTemplateState: $TSFixMe;
  newFrameworkMetaPatch: $TSFixMe;
  featureTemplateKey: string;
}): Definition => {
  const newFrameworkMetaForThisFeatureTemplate = {
    ...prevDefinition.featureTemplatesData?.[featureTemplateKey]?.frameworkMeta,
    ...newFrameworkMetaPatch,
  };

  const newFeatureTemplateDataForThisFeatureTemplate = {
    ...prevDefinition.featureTemplatesData?.[featureTemplateKey],
    state:
      newFeatureTemplateState ??
      prevDefinition.featureTemplatesData?.[featureTemplateKey]?.state,
    frameworkMeta: newFrameworkMetaForThisFeatureTemplate,
  };

  return {
    ...prevDefinition,
    featureTemplatesData: {
      ...(prevDefinition.featureTemplatesData ?? {}),
      [featureTemplateKey]: newFeatureTemplateDataForThisFeatureTemplate,
    },
  };
};
