import { addEdgeIncludingReverseEdge } from "@sutro/studio2-quarantine/definitions/definition-updaters/add-edge-including-reverse-edge";
import { addEffectInstructionToAction } from "@sutro/studio2-quarantine/definitions/definition-updaters/add-effect-instruction-to-action";
import { addPrecondition } from "@sutro/studio2-quarantine/definitions/definition-updaters/add-precondition";
import { addRole } from "@sutro/studio2-quarantine/definitions/definition-updaters/add-role";
import { changeActionName } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-action-name";
import { changeCreateEffectDtIdToCreate } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-create-effect-dt-id-to-create";
import { changeDtName } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-dt-name";
import { changeEdgeQuantity } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-edge-quantity";
import { changeEffectFieldInstructionCreatorProvidedConstant } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-effect-field-instruction-creator-provided-constant";
import { changeEffectFieldInstructionPath } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-effect-field-instruction-path";
import { changeEffectFieldInstructionSourceVariable } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-effect-field-instruction-source-variable";
import { changeEffectType } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-effect-type";
import { changeEffectVariable } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-effect-variable";
import { changeEffectVariableCreatorProvidedConstant } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-effect-variable-creator-provided-constant";
import { changeEffectVariablePath } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-effect-variable-path";
import { changeFieldName } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-field-name";
import { changeFieldNameAndFieldNameShouldBeTypeName } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-field-name-and-field-name-should-be-type-name";
import { changeHasEdgeHasId } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-has-edge-has-id";
import { changeHasInteractionAnnotation } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-has-interaction-annotation";
import { changeIdentityDt } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-identity-dt";
import { changeLinkImage } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-link-image";
import { changeLinkImageAsIcon } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-link-image-as-icon";
import { changePreconditionVariable } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-precondition-variable";
import { changePreconditionVariablePath } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-precondition-variable-path.1";
import { changeRegex } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-regex";
import { changeSubText } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-sub-text";
import { changeTextLength } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-text-length";
import { changeTextLengthValidation } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-text-length-validation";
import { changeUpdateEffectDtIdToUpdate } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-update-effect-dt-id-to-update";
import { changeUpdateGlobalRoleMembershipEffectRoleEdgeIdParams } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-update-global-role-membership-effect-role-edge-id-params";
import { changeWhoCanSeeVariables } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-who-can-see-variables";
import { createAction } from "@sutro/studio2-quarantine/definitions/definition-updaters/create-action";
import { deleteAction } from "@sutro/studio2-quarantine/definitions/definition-updaters/delete-action";
import { changeDeleteEffectDtIdToDelete } from "@sutro/studio2-quarantine/definitions/definition-updaters/delete-effect-dt-id-to-delete";
import { executeDefinitionUpdateInstruction } from "@sutro/studio2-quarantine/definitions/definition-updaters/execute-definition-update-instruction";
import { afterUpdateInstanceToAddToSetVariable } from "@sutro/studio2-quarantine/definitions/definition-updaters/index";
import { changeInvokeWebhookEffectDtIdToPopulate } from "@sutro/studio2-quarantine/definitions/definition-updaters/invoke-webhook-effect-dt-id-to-populate";
import { removingDt } from "@sutro/studio2-quarantine/definitions/definition-updaters/remove-dt";
import { removeEdge } from "@sutro/studio2-quarantine/definitions/definition-updaters/remove-edge";
import { removeEffectInstructionToAction } from "@sutro/studio2-quarantine/definitions/definition-updaters/remove-effect-instruction-to-action";
import { removePrecondition } from "@sutro/studio2-quarantine/definitions/definition-updaters/remove-precondition";
import {
  manuallyReorderEdge,
  reorderEdge,
} from "@sutro/studio2-quarantine/definitions/definition-updaters/reorder-field";
import { setDtOrEdgePluginData } from "@sutro/studio2-quarantine/definitions/definition-updaters/set-dt-plugin-data-key";
import { afterSetEffectPluginDataKey } from "@sutro/studio2-quarantine/definitions/definition-updaters/set-effect-plugin-data-key";
import { afterSetActionPluginDataKey } from "@sutro/studio2-quarantine/definitions/definition-updaters/set-plugin-data-key";
import { afterSetPreconditionPluginDataKey } from "@sutro/studio2-quarantine/definitions/definition-updaters/set-precondition-plugin-data-key";
import { toggleOptional } from "@sutro/studio2-quarantine/definitions/definition-updaters/toggle-optional";
import { afterUpdateInstanceWithSetToUpdateVariable } from "@sutro/studio2-quarantine/definitions/definition-updaters/update-instance-with-set-to-update-variable";
import { afterUpdateNavigateOnceDone } from "@sutro/studio2-quarantine/definitions/definition-updaters/update-navigate-once-done";
import { afterUpdateSetEdgeIdAndDirection } from "@sutro/studio2-quarantine/definitions/definition-updaters/update-set-edge-id-and-direction";
import { ActionModifiers } from "@sutro/studio2-quarantine/types/action-modifiers";
import { DtModifiers } from "@sutro/studio2-quarantine/types/dt-modifiers";
import { Draft, produce } from "immer";
import { Definition, jsonCopy } from "sutro-common";

export const getDtAndActionModifiers = ({
  definition,
  onChangeDefinition,
}: {
  definition: Definition;
  onChangeDefinition: ({
    newDefinition,
    causedByUserAction,
  }: {
    newDefinition: Definition;
    causedByUserAction?: boolean;
  }) => void;
}) => {
  // We have a bunch of helper functions that update DTs in response to UI actions. To avoid every callsite needing to provide dataTypes and updateDefinitionAfterValidating as context, and every helper needing to use produce and updateDefinitionAfterValidating, we abstract that out into this decorator
  const decorateCallbackForUpdatingDefinition =
    <A extends unknown[], R extends object | void>(
      fn: (draft: Draft<Definition>, ...args: A) => R
    ) =>
    (...params: A): R => {
      let copiedResult;

      // Very subtle, but we need to pass in a copy to make updateDefinition() be able to do updates in batches. The key is that we are able to update the actual contents of definition (passed in above), which we can't if we pass it into produce, since this will freeze it
      const newDefinition = produce({ ...definition }, (draftDefinition) => {
        const rawResult = fn(draftDefinition, ...params);
        copiedResult =
          rawResult !== undefined ? jsonCopy(rawResult) : undefined;
      });

      const firstParam: { causedByUserAction?: boolean } = params[0] ?? {};
      const causedByUserAction: boolean =
        firstParam?.causedByUserAction ?? true;
      // produce returns the newDefinition in the {actions, dataTypes} format, so switch to the "patch" format that newActions expects
      onChangeDefinition({
        newDefinition,
        causedByUserAction,
      });

      return copiedResult as R;
    };

  const dtModifiers: DtModifiers = {
    executeInstruction: decorateCallbackForUpdatingDefinition(
      executeDefinitionUpdateInstruction
    ),
    changeDtName: decorateCallbackForUpdatingDefinition(changeDtName),
    changeIdentityDt: decorateCallbackForUpdatingDefinition(changeIdentityDt),
    removeDt: decorateCallbackForUpdatingDefinition(removingDt),
    addEdge: decorateCallbackForUpdatingDefinition(addEdgeIncludingReverseEdge),
    changeHasEdgeHasId:
      decorateCallbackForUpdatingDefinition(changeHasEdgeHasId),
    removeEdge: decorateCallbackForUpdatingDefinition(removeEdge),
    changeFieldName: decorateCallbackForUpdatingDefinition(changeFieldName),
    manuallyReorderField:
      decorateCallbackForUpdatingDefinition(manuallyReorderEdge),
    reorderEdge: decorateCallbackForUpdatingDefinition(reorderEdge),
    changeEdgeQuantity:
      decorateCallbackForUpdatingDefinition(changeEdgeQuantity),
    changeInteractionAnnotation: decorateCallbackForUpdatingDefinition(
      changeHasInteractionAnnotation
    ),
    /**
     * @todo SUT-2517 We should probably remove a bunch of these methods that are simply changing some values in the plugin data of an edge/dt.
     */
    changeTextLength: decorateCallbackForUpdatingDefinition(changeTextLength),
    changeTextLengthValidation: decorateCallbackForUpdatingDefinition(
      changeTextLengthValidation
    ),
    changeSubText: decorateCallbackForUpdatingDefinition(changeSubText),
    changeRegex: decorateCallbackForUpdatingDefinition(changeRegex),
    toggleOptional: decorateCallbackForUpdatingDefinition(toggleOptional),
    changeLinkImage: decorateCallbackForUpdatingDefinition(changeLinkImage),
    changeLinkImageAsIcon: decorateCallbackForUpdatingDefinition(
      changeLinkImageAsIcon
    ),
    changeFieldNameAndFieldNameShouldBeTypeName:
      decorateCallbackForUpdatingDefinition(
        changeFieldNameAndFieldNameShouldBeTypeName
      ),
    changeWhoCanSeeVariables: decorateCallbackForUpdatingDefinition(
      changeWhoCanSeeVariables
    ),
    addRole: decorateCallbackForUpdatingDefinition(addRole),
    // Non annotated because this takes in the full new definition
    updateDefinitionInStateAfterValidating: onChangeDefinition,
    setPluginData: decorateCallbackForUpdatingDefinition(setDtOrEdgePluginData),
  };

  const actionModifiers: ActionModifiers = {
    createAction: decorateCallbackForUpdatingDefinition(createAction),
    changeActionName: decorateCallbackForUpdatingDefinition(changeActionName),
    deleteAction: decorateCallbackForUpdatingDefinition(deleteAction),
    addEffectInstructionToAction: decorateCallbackForUpdatingDefinition(
      addEffectInstructionToAction
    ),
    removeEffectInstructionToAction: decorateCallbackForUpdatingDefinition(
      removeEffectInstructionToAction
    ),
    changeEffectType: decorateCallbackForUpdatingDefinition(changeEffectType),
    changeCreateEffectDtIdToCreate: decorateCallbackForUpdatingDefinition(
      changeCreateEffectDtIdToCreate
    ),
    changeUpdateEffectDtIdToUpdate: decorateCallbackForUpdatingDefinition(
      changeUpdateEffectDtIdToUpdate
    ),
    changeEffectFieldInstructionPath: decorateCallbackForUpdatingDefinition(
      changeEffectFieldInstructionPath
    ),
    changeEffectFieldInstructionSourceVariable:
      decorateCallbackForUpdatingDefinition(
        changeEffectFieldInstructionSourceVariable
      ),
    changeEffectVariable:
      decorateCallbackForUpdatingDefinition(changeEffectVariable),
    changeEffectVariablePath: decorateCallbackForUpdatingDefinition(
      changeEffectVariablePath
    ),
    changeEffectVariableCreatorProvidedConstant:
      decorateCallbackForUpdatingDefinition(
        changeEffectVariableCreatorProvidedConstant
      ),
    changeEffectFieldInstructionCreatorProvidedConstant:
      decorateCallbackForUpdatingDefinition(
        changeEffectFieldInstructionCreatorProvidedConstant
      ),
    changeUpdateGlobalRoleMembershipEffectRoleEdgeId:
      decorateCallbackForUpdatingDefinition(
        changeUpdateGlobalRoleMembershipEffectRoleEdgeIdParams
      ),
    changeDeleteEffectDtIdToDelete: decorateCallbackForUpdatingDefinition(
      changeDeleteEffectDtIdToDelete
    ),
    changeInvokeWebhookEffectDtIdToPopulate:
      decorateCallbackForUpdatingDefinition(
        changeInvokeWebhookEffectDtIdToPopulate
      ),
    addPrecondition: decorateCallbackForUpdatingDefinition(addPrecondition),
    changePreconditionVariablePath: decorateCallbackForUpdatingDefinition(
      changePreconditionVariablePath
    ),
    changePreconditionVariable: decorateCallbackForUpdatingDefinition(
      changePreconditionVariable
    ),
    removePreconditionVariable:
      decorateCallbackForUpdatingDefinition(removePrecondition),

    toggleIntoSetEffect: {
      updateInstanceWithSetToUpdateVariable:
        decorateCallbackForUpdatingDefinition(
          afterUpdateInstanceWithSetToUpdateVariable
        ),
      updateInstanceToAddToSetVariable: decorateCallbackForUpdatingDefinition(
        afterUpdateInstanceToAddToSetVariable
      ),
      updateSetEdgeIdAndDirection: decorateCallbackForUpdatingDefinition(
        afterUpdateSetEdgeIdAndDirection
      ),
    },
    updateNavigateOnceDone: decorateCallbackForUpdatingDefinition(
      afterUpdateNavigateOnceDone
    ),
    setPluginDataKey: decorateCallbackForUpdatingDefinition(
      afterSetActionPluginDataKey
    ),
    // actionModifiers.onToggleAnalytics = decorateCallbackForUpdatingDefinition(
    //   toggleAnalytics
    // );
    setEffectPluginDataKey: decorateCallbackForUpdatingDefinition(
      afterSetEffectPluginDataKey
    ),
    setPreconditionPluginDataKey: decorateCallbackForUpdatingDefinition(
      afterSetPreconditionPluginDataKey
    ),
  };

  return { dtModifiers, actionModifiers };
};
