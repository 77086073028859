import { ContainerDataType, TEXT_DT_ID } from "sutro-common";
import { isEdgeSingleEdge } from "sutro-common/edges/is-edge-single-edge";
import { TEXT_LENGTH } from "sutro-common/primitive-text";

export const setLongTextFieldsTextLength = (dataType: ContainerDataType) => {
  for (const edge of dataType.edges.filter(isEdgeSingleEdge)) {
    const fieldNamesThatShouldAutoBeLongText = ["description", "body"];
    const hasEdgeIsText = edge.relatedDtId === TEXT_DT_ID;
    if (
      hasEdgeIsText &&
      fieldNamesThatShouldAutoBeLongText.includes(edge.fieldName.toLowerCase())
    ) {
      edge.textLength = TEXT_LENGTH.long;
    }
  }
};
