import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import {
  ContainerDataType,
  DataType,
  IdentityDataType,
  isDtAppRoot,
  isDtUser,
  UnionDataType,
} from "sutro-common";

import { Draft } from "immer";
import type { DataTypeEdge } from "sutro-common/edges/data-type-edge";
import {
  EDGE_QUANTITY_TYPES,
  MODIFIER_CAN_ADD_REMOVE_TYPES,
  WHO_CAN_ADD_REMOVE_TYPES,
} from "sutro-common/edges/data-type-edge";
import { getEdgeIdAndDirectionForEdge } from "sutro-common/edges/get-edge-id-and-direction-for-edge";
import { isDataTypeSomeEdge } from "sutro-common/edges/is-edge-some-edge";

import { setEdgeQuantity } from "../definition-updaters/index.js";
export const adjustOtherAclsBasedOnWhoCanAddRemove = ({
  edge,
  dataType,
  containedDt,
  correspondingBelongsToEdge,
  changeTracker,
}: {
  edge: DataTypeEdge;
  dataType: ContainerDataType;
  containedDt: Exclude<DataType, UnionDataType | IdentityDataType>;
  correspondingBelongsToEdge: Draft<DataTypeEdge>;
  changeTracker: ChangeTracker;
}) => {
  if (isDataTypeSomeEdge(edge)) {
    // We can heuristically set modifierCanAddRemove in this case based on whoCanAddRemove
    // I.e. if X has a list of users, it only makes sense to modify a list of other users, or to toggle yourself in and out of someone elses list (anybody adding anybody is silly, as is user only toggling self)
    if (isDtUser(containedDt) && isDtAppRoot(dataType) === false) {
      if (
        edge.whoCanAddRemove?.type === WHO_CAN_ADD_REMOVE_TYPES.ANYBODY &&
        edge.modifierCanAddRemove?.type !==
          MODIFIER_CAN_ADD_REMOVE_TYPES.OWN_ONLY
      ) {
        changeTracker.trackChange(
          "Set edge.modifierCanAddRemove.type to OWN_ONLY for edge",
          {
            edge,
          }
        );
        edge.modifierCanAddRemove = {
          ...edge.modifierCanAddRemove,
          type: MODIFIER_CAN_ADD_REMOVE_TYPES.OWN_ONLY,
        };
      } else if (
        edge.whoCanAddRemove?.type === WHO_CAN_ADD_REMOVE_TYPES.OWNER &&
        edge.modifierCanAddRemove?.type !==
          MODIFIER_CAN_ADD_REMOVE_TYPES.ANYBODYS
      ) {
        changeTracker.trackChange(
          "Set edge.modifierCanAddRemove.type to ANYBODYS for edge",
          {
            edge,
          }
        );
        edge.modifierCanAddRemove = {
          ...edge.modifierCanAddRemove,
          type: MODIFIER_CAN_ADD_REMOVE_TYPES.ANYBODYS,
        };
      }
    }
  }

  // If anybody's can be added then we need to ensure child belongsTo SOME parent
  if (
    isDataTypeSomeEdge(edge) &&
    edge.modifierCanAddRemove?.type ===
      MODIFIER_CAN_ADD_REMOVE_TYPES.ANYBODYS &&
    isDtAppRoot(dataType) === false
  ) {
    if (isDataTypeSomeEdge(correspondingBelongsToEdge) === false) {
      setEdgeQuantity({
        draftDt: dataType,
        edgeIdAndDirection: getEdgeIdAndDirectionForEdge(edge),
        newEdgeQuantity: EDGE_QUANTITY_TYPES.SOME,
      });

      changeTracker.trackChange(
        "modifierCanAddRemove.type === MODIFIER_CAN_ADD_REMOVE_TYPES.ANYBODYS so set edgeBelongsQuantity on contained to SOME",
        { correspondingBelongsToEdge, hasEdge: edge }
      );
    }
  }
};
