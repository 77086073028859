import { Definition } from "sutro-common";

import { getUpdatedAndValidatedDefinition } from "~/lib/definitions/definition-validation";

export const getPatchForUpdatingDefinitionInStateAfterValidating = ({
  newDefinition,
  isInitializing,
}: {
  newDefinition: Definition;
  isInitializing: boolean;
}):
  | {
      patchForUpdatingDefinition: Partial<Definition>;
      patchForValidDefinition: Partial<Definition>;
    }
  | undefined => {
  console.groupCollapsed("Validation");
  console.log("getPatchForUpdatingDefinitionInStateAfterValidating", {
    newDefinition,
    isInitializing,
  });

  const {
    updatedDefinition,
    validatedDefinition: newValidatedDefinition,
    error,
    changeTracker,
  } = getUpdatedAndValidatedDefinition({
    definition: newDefinition,
  });

  if (changeTracker.logSize > 0) {
    changeTracker.showChanges("VALIDATION MADE CHANGES");
  }

  if (error !== undefined) {
    alert(error);
    console.groupEnd();
    return;
  }

  const {
    dataTypes: updatedDataTypes,
    actions: updatedActions,
    appMeta: updatedAppMeta,
  } = updatedDefinition;

  const {
    dataTypes: newValidatedDataTypes,
    actions: newValidatedActions,
    appMeta: newValidatedAppMeta,
  } = newValidatedDefinition;

  const patchForUpdatingDefinition: Partial<Definition> = {
    dataTypes: updatedDataTypes,
    /** @todo:  will need to tweak once we actually mark actions as isIncomplete and remove them */
    actions: updatedActions,
    appMeta: updatedAppMeta,
    featureTemplatesData: newDefinition.featureTemplatesData,
    uiAnnotations: newDefinition.uiAnnotations,
    slang: newDefinition.slang,
  };

  const patchForValidDefinition: Partial<Definition> = {
    dataTypes: newValidatedDataTypes,
    actions: newValidatedActions,
    appMeta: newValidatedAppMeta,
    featureTemplatesData: newDefinition.featureTemplatesData,
    uiAnnotations: newDefinition.uiAnnotations,
    slang: newDefinition.slang,
  };

  console.groupEnd();

  return { patchForUpdatingDefinition, patchForValidDefinition };
};
