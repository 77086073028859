import { DataTypeEdge } from "sutro-common/edges/data-type-edge";

import { copyFeatureTemplateMetaFromEdgeToEdge } from "./copy-feature-template-meta-from-edge-to-edge.js";
export const syncFeatureTemplateMetaBetweenEdges = ({
  edgeA,
  edgeB,
}: {
  edgeA: DataTypeEdge;
  edgeB: DataTypeEdge;
}) => {
  copyFeatureTemplateMetaFromEdgeToEdge({ edgeA, edgeB });
  copyFeatureTemplateMetaFromEdgeToEdge({ edgeA: edgeB, edgeB: edgeA });
};
