import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { Draft } from "immer";
import groupBy from "lodash.groupby";
import {
  DataType,
  getDt,
  getUserDt,
  isDataTypeEdgeBelongsToEdge,
  isDataTypeEdgeHasEdge,
  isDtAppRoot,
  isDtContainer,
  isDtUser,
} from "sutro-common";
import { isDefined } from "sutro-common/collection-helpers/is-defined";
import {
  DataTypeEdge,
  EDGE_QUANTITY_TYPES,
  MODIFIER_CAN_ADD_REMOVE_TYPES,
  WHO_CAN_ADD_REMOVE_TYPES,
} from "sutro-common/edges/data-type-edge";
import { getEdgeIdAndDirectionForEdge } from "sutro-common/edges/get-edge-id-and-direction-for-edge";
import { getReverseEdge } from "sutro-common/edges/get-reverse-edge";
import { isCreatorEdge } from "sutro-common/edges/is-creator-edge";
import { isDataTypeSomeEdge } from "sutro-common/edges/is-edge-some-edge";
import {
  applyPluginDataPatch,
  EntityWithPluginData,
} from "sutro-common/plugins/plugin-data";

import { setEdgeQuantity } from "../definition-updaters/index.js";
const setIsCreatorEdge = (
  edge: EntityWithPluginData,
  isCreatorEdge: boolean
) => {
  applyPluginDataPatch(edge, "creationMeta", {
    isCreatorEdge,
  });
};

const isHasEdgeToAnAppContainer =
  (draftDataTypes: Draft<DataType>[]) => (e: DataTypeEdge) => {
    if (e.relatedDtId === undefined) {
      return false;
    }
    const relatedDt = getDt(draftDataTypes, e.relatedDtId);
    return (
      isDefined(relatedDt) &&
      isDataTypeEdgeHasEdge(e) &&
      isDtContainer(relatedDt) &&
      isDtUser(relatedDt) === false &&
      isDtAppRoot(relatedDt) === false
    );
  };

/**
 * This function looks at all the HAS edges of the User DT.
 *
 * If an edge points to a Container DT that is not the User DT, then it takes action:
 *   - If the other DT does not have a Creator edge, then it marks the edge as a creator edge.
 *   - If the other DT already has a Creator edge, then it makes sure that the edge is a SINGLE edge.
 *
 * Next, it groups the HAS edges from the User DT to Container DTs by the DT they point to. This excludes edges to
 * the User DT and edges to the App Root DT.
 *
 * If there are multiple edges to the same DT, then it takes action:
 *  - If there is no creator edge, then it does nothing.
 *  - If there is a creator edge and it's a SOME edge, then it does nothing.
 *  - If there is a creator edge and it's a SINGLE edge, then:
 *    - If there are any SOME edges to this DT, then it
 *      - unmarks the SINGLE edge as a creator edge
 *      - marks the SOME edge as a creator edge.
 */
export const updateDtsToEnsureEdgesAreMarkedAsCreatorEdgeAppropriately = ({
  draftDataTypes,
  changeTracker,
}: {
  draftDataTypes: Draft<DataType[]>;
  changeTracker: ChangeTracker;
}) => {
  const userDt = getUserDt(draftDataTypes);

  for (const edge of userDt.edges.filter(
    isHasEdgeToAnAppContainer(draftDataTypes)
  )) {
    const relatedDt = getDt(draftDataTypes, edge.relatedDtId);

    const reverseEdge = getReverseEdge({
      edge,
      dataTypes: draftDataTypes,
    });

    if (reverseEdge !== undefined) {
      const relatedDtCreatorEdge = relatedDt?.edges.find(
        (e) => isDataTypeEdgeBelongsToEdge(e) && isCreatorEdge(e)
      );

      if (relatedDtCreatorEdge === undefined) {
        setIsCreatorEdge(edge, true);
        setIsCreatorEdge(reverseEdge, true);

        changeTracker.trackChange(
          `Set edge ${edge.edgeId} from user to be creator edge`
        );
      } else {
        const shouldMakeTheEdgeSingle =
          relatedDtCreatorEdge.quantity === EDGE_QUANTITY_TYPES.SOME;

        if (shouldMakeTheEdgeSingle) {
          setEdgeQuantity({
            draftDt: relatedDt,
            edgeIdAndDirection:
              getEdgeIdAndDirectionForEdge(relatedDtCreatorEdge),
            newEdgeQuantity: EDGE_QUANTITY_TYPES.SINGLE,
          });
          changeTracker.trackChange(
            `Set edge ${edge.edgeId} to belong to single user, since it is a creator edge`
          );
        }
      }
    }

    const edgeBundles = groupBy(userDt.edges, "relatedDtId");

    // Covering a case where we have a SINGLE creator edge to X, then user adds
    // a SOME edge to X. In this case, we need to unmark the SINGLE edge as creator, instead
    // mark the SOME edge to be the creator edge.
    Object.values(edgeBundles)
      .filter((edgeBundle) => edgeBundle.length > 1)
      .forEach((edgeBundle) => {
        const currentCreatorEdge = edgeBundle.find(isCreatorEdge);

        if (
          currentCreatorEdge === undefined ||
          isDataTypeSomeEdge(currentCreatorEdge)
        ) {
          return;
        }

        const someEdges = edgeBundle.filter(isDataTypeSomeEdge);

        // If we have SOME edges that are not eligible to be marked as creator,
        // run the required operations on them to make them eligible.
        if (someEdges.length > 0) {
          const nextCreatorEdge = someEdges.at(0);

          if (
            isDefined(nextCreatorEdge) &&
            (nextCreatorEdge.modifierCanAddRemove?.type !==
              MODIFIER_CAN_ADD_REMOVE_TYPES.OWN_ONLY ||
              nextCreatorEdge.whoCanAddRemove?.type !==
                WHO_CAN_ADD_REMOVE_TYPES.OWNER)
          ) {
            nextCreatorEdge.modifierCanAddRemove = {
              type: MODIFIER_CAN_ADD_REMOVE_TYPES.OWN_ONLY,
            };
            nextCreatorEdge.whoCanAddRemove = {
              type: WHO_CAN_ADD_REMOVE_TYPES.OWNER,
            };
            changeTracker.trackChange(
              "Set edge modifierCanAddRemove to OWN_ONLY and whoCanAddRemove to OWNER since edge was creator edge",
              { nextCreatorEdge }
            );
          }

          const currentCreatorReverseEdge = getReverseEdge({
            edge: currentCreatorEdge,
            dataTypes: draftDataTypes,
          });

          const nextCreatorReverseEdge = getReverseEdge({
            edge: nextCreatorEdge,
            dataTypes: draftDataTypes,
          });
          if (
            !isDefined(currentCreatorReverseEdge) ||
            !isDefined(nextCreatorReverseEdge) ||
            !isDefined(currentCreatorEdge) ||
            !isDefined(nextCreatorEdge)
          ) {
            return;
          }

          setIsCreatorEdge(currentCreatorEdge, false);
          setIsCreatorEdge(currentCreatorReverseEdge, false);

          setIsCreatorEdge(nextCreatorEdge, true);
          setIsCreatorEdge(nextCreatorReverseEdge, true);

          changeTracker.trackChange(
            `Unmarking edge ${currentCreatorEdge.edgeId} as creator, instead marking edge ${nextCreatorEdge.edgeId} as a creator edge.`
          );
        }
      });
  }
};
