import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { ContainerDataType } from "sutro-common";
import { USER_DT_ID } from "sutro-common/dt-id";
import { MODIFIER_CAN_ADD_REMOVE_TYPES } from "sutro-common/edges/data-type-edge";
import { isCreatorEdge } from "sutro-common/edges/is-creator-edge";
import { isDataTypeSomeEdge } from "sutro-common/edges/is-edge-some-edge";

// Make sure `modifierCanAddRemove` is set correctly for edges from User
export const ensureModifierCanAddRemoveIsSetCorrectlyForUser = (
  userDt: ContainerDataType,
  changeTracker: ChangeTracker
): void => {
  for (const edge of userDt.edges.filter(isDataTypeSomeEdge)) {
    if (
      isCreatorEdge(edge) === false &&
      edge.relatedDtId !== USER_DT_ID &&
      edge.modifierCanAddRemove?.type === MODIFIER_CAN_ADD_REMOVE_TYPES.OWN_ONLY
    ) {
      changeTracker.trackChange(
        "Set edge modifierCanAddRemove to ANYBODYS since edge wasnt creator edge and it was set to " +
          edge.modifierCanAddRemove.type,
        { edge }
      );
      edge.modifierCanAddRemove = {
        type: MODIFIER_CAN_ADD_REMOVE_TYPES.ANYBODYS,
      };
    } else if (
      isCreatorEdge(edge) &&
      edge.modifierCanAddRemove?.type !== MODIFIER_CAN_ADD_REMOVE_TYPES.OWN_ONLY
    ) {
      edge.modifierCanAddRemove = {
        type: MODIFIER_CAN_ADD_REMOVE_TYPES.OWN_ONLY,
      };

      changeTracker.trackChange(
        "Set edge modifierCanAddRemove to OWN_ONLY since edge was creator edge",
        { edge }
      );
    }
  }
};
