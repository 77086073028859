import { doesObjMatchFeatureTemplateAndIdempotencyKey } from "@sutro/studio2-quarantine/lib/feature-template-helpers";
import type { DataTypeEdge } from "sutro-common/edges/data-type-edge";
export const copyFeatureTemplateMetaFromEdgeToEdge = ({
  edgeA,
  edgeB,
}: {
  edgeA: DataTypeEdge;
  edgeB: DataTypeEdge;
}) => {
  const featureTemplateMetaB = edgeB.featureTemplateMeta ?? [];

  for (const entry of edgeA.featureTemplateMeta ?? []) {
    if (
      doesObjMatchFeatureTemplateAndIdempotencyKey({
        obj: edgeB,
        idempotencyKeyAndFeatureTemplateKey: entry,
      }) === false
    ) {
      featureTemplateMetaB.push(entry);
    }
  }
  edgeB.featureTemplateMeta = featureTemplateMetaB;
};
