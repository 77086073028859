import {
  areEffectVariablesIncomplete,
  isFieldInstructionIncomplete,
} from "@sutro/studio2-quarantine/lib/field-instruction-helpers";
import {
  DataType,
  EFFECT_TYPES,
  SerializedEffectInstruction,
} from "sutro-common";

type IsEffectInstructionIncompleteResult =
  | { isIncomplete: false }
  | { isIncomplete: true; error?: "DEAD_DT_EDGE_REFERENCE" | "NO_EFFECT_ID" };
export const isEffectInstructionIncomplete = ({
  effectInstruction,
  dataTypes,
}: {
  effectInstruction: SerializedEffectInstruction;
  dataTypes: DataType[];
}): IsEffectInstructionIncompleteResult => {
  // First we generically verify fieldInstructions and subVariable, then we do effectType specific validation
  const { effectType, effectId } = effectInstruction;

  if (effectId === undefined) {
    return { isIncomplete: true, error: "NO_EFFECT_ID" };
  }

  if ("fieldInstructions" in effectInstruction) {
    const incompleteFieldInstructions =
      effectInstruction.fieldInstructions.filter(
        (fieldInstruction) =>
          isFieldInstructionIncomplete({ fieldInstruction, dataTypes })
            .isIncomplete
      );

    if (incompleteFieldInstructions.length > 0) {
      console.log(
        "effectInstruction is incomplete because of fieldInstructions",
        {
          effectInstruction: JSON.parse(JSON.stringify(effectInstruction)),
          invalidIndex: effectInstruction.fieldInstructions.findIndex(
            (fieldInstruction) =>
              isFieldInstructionIncomplete({ fieldInstruction, dataTypes })
                .isIncomplete
          ),
        }
      );

      const containsInstructionsWithDeadReferences =
        incompleteFieldInstructions.some((fieldInstruction) => {
          const validation = isFieldInstructionIncomplete({
            fieldInstruction,
            dataTypes,
          });
          if (
            validation.isIncomplete &&
            validation.error === "DEAD_DT_EDGE_REFERENCE"
          ) {
            return true;
          }
          return false;
        });

      if (containsInstructionsWithDeadReferences) {
        return { isIncomplete: true, error: "DEAD_DT_EDGE_REFERENCE" };
      } else {
        return { isIncomplete: true };
      }
    }
  }

  if (
    "variables" in effectInstruction &&
    /** @todo:  implement separate validation for this case. We exclude in this case because this check is intended for the inclusion of "keyed typed variables", which VARIABLE doesn't use */
    effectInstruction.effectType !== EFFECT_TYPES.VARIABLE
  ) {
    if (areEffectVariablesIncomplete({ effectInstruction, dataTypes })) {
      return { isIncomplete: true };
    }
  }

  if (effectType === EFFECT_TYPES.CREATE) {
    if (effectInstruction.idOfDTToCreate === "") {
      return { isIncomplete: true };
    }
  } else if (effectType === EFFECT_TYPES.UPDATE) {
    if (effectInstruction.idOfDTToUpdate === "") {
      return { isIncomplete: true };
    }
  } else if (effectType === EFFECT_TYPES.UPDATE_GLOBAL_ROLE_MEMBERSHIP) {
    return { isIncomplete: effectInstruction.roleEdgeId === null };
  } else if (
    effectType === EFFECT_TYPES.CHARGE_USER ||
    effectType === EFFECT_TYPES.REGISTER_STRIPE_EXPRESS_ACCOUNT ||
    effectType === EFFECT_TYPES.SET_UP_SUBSCRIPTION_PAYMENTS ||
    effectType === EFFECT_TYPES.SEND_EMAIL ||
    effectType === EFFECT_TYPES.CANCEL_STRIPE_SUBSCRIPTION
  ) {
    // Do nothing special in this case
  } else if (
    effectType === EFFECT_TYPES.TOGGLE_INTO_SET ||
    effectType === EFFECT_TYPES.DELETE ||
    effectType === EFFECT_TYPES.INVOKE_WEBHOOK
  ) {
    console.warn(`Validation for effectType ${effectType} not implemented`);
    return { isIncomplete: false };
  } else if (effectType === EFFECT_TYPES.VARIABLE) {
    /** @todo:  implement this case */
    return { isIncomplete: false };
  } else {
    throw new Error(
      `Effect unimplemented in isEffectInstructionIncomplete: ${effectType}`
    );
  }
  return { isIncomplete: false };
};
