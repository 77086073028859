import {
  DataType,
  DT_TYPES,
  DtId,
  getUserDt,
  isDtContainer,
  isDtUnion,
  not,
} from "sutro-common";
import type { DataTypeSomeEdge } from "sutro-common/edges/data-type-edge";
import { WHO_CAN_ADD_REMOVE_TYPES } from "sutro-common/edges/data-type-edge";
import { isCreatorEdge } from "sutro-common/edges/is-creator-edge";
import { isDataTypeSomeEdge } from "sutro-common/edges/is-edge-some-edge";
import { applyPluginDataPatch } from "sutro-common/plugins/plugin-data";

import { StudioDefinitionPlugin } from "../types.js";
/**
 * Heuristically, an edge to a Reaction is:
 * - a SOME edge
 * - to a container
 * - with a single edge to an Enum
 */

const hasSingleEdgeToEnum = (
  possibleReaction: DataType,
  dtThatMayHaveReactions: DataType,
  dtsById: Record<DtId, DataType>
) => {
  const userDefinedEdges = possibleReaction.edges.filter(
    (edge) =>
      edge.relatedDtId !== dtThatMayHaveReactions.id &&
      edge?.pluginData?.creationMeta?.isCreatorEdge !== true
  );

  if (userDefinedEdges.length !== 1) {
    return false;
  }
  const edgeFromPossibleReaction = userDefinedEdges[0];
  const possibleReactionFieldDt = dtsById[edgeFromPossibleReaction.relatedDtId];

  if (
    not(isDtUnion)(possibleReactionFieldDt) ||
    possibleReactionFieldDt.type !== DT_TYPES.ENUM
  ) {
    return false;
  }

  return true;
};

export const updateDefinitionToTagEdgesToReactions: StudioDefinitionPlugin = ({
  draftDefinition: { dataTypes },
  changeTracker,
}) => {
  const dtsById: Record<DtId, DataType> = {};
  dataTypes.forEach((dt) => {
    dtsById[dt.id] = dt;
  });

  dataTypes.filter(isDtContainer).forEach((dtThatMayHaveReactions) => {
    dtThatMayHaveReactions.edges.filter(isDataTypeSomeEdge).forEach((edge) => {
      const { relatedDtId } = edge;
      const possibleReaction = dtsById[relatedDtId];

      if (not(isDtContainer)(possibleReaction)) {
        return;
      }

      if (
        hasSingleEdgeToEnum(possibleReaction, dtThatMayHaveReactions, dtsById)
      ) {
        if (possibleReaction.pluginData?.reactions?.isReaction !== true) {
          applyPluginDataPatch(possibleReaction, "reactions", {
            isReaction: true,
          });

          changeTracker.trackChange(
            `Marked dt ${possibleReaction.id} as a reaction`
          );
        }
        const reactionCreatorEdge = getUserDt(dataTypes).edges.find(
          (e) => e.relatedDtId === possibleReaction.id && isCreatorEdge(e)
        ) as DataTypeSomeEdge;
        if (
          reactionCreatorEdge.whoCanAddRemove?.type !==
          WHO_CAN_ADD_REMOVE_TYPES.NOBODY
        ) {
          reactionCreatorEdge.whoCanAddRemove = {
            type: WHO_CAN_ADD_REMOVE_TYPES.NOBODY,
          };
          applyPluginDataPatch(reactionCreatorEdge, "reactions", {
            isReaction: true,
          });
          changeTracker.trackChange(
            `Marked edge ${reactionCreatorEdge.edgeId} as a reaction`
          );
        }
      } else {
        if (possibleReaction.pluginData?.reactions?.isReaction === true) {
          applyPluginDataPatch(possibleReaction, "reactions", {
            isReaction: false,
          });

          changeTracker.trackChange(
            `Marked dt ${possibleReaction.id} as no longer a reaction`
          );
        }
      }
    });
  });
};
