import {
  modifyEdges,
  removeEdges,
} from "@sutro/studio2-quarantine/lib/dt-manipulation-helpers";
import { produce } from "immer";
import { DataType } from "sutro-common";

export const getDTsWithIncompleteRemoved = (dataTypes: DataType[]) =>
  produce(
    dataTypes.filter((dt) => dt.isIncomplete !== true),
    (draftDataTypes) => {
      draftDataTypes.forEach((dt) => {
        removeEdges(dt, (edge) => edge.isIncomplete === true);
        modifyEdges(dt, (edge) => ({ ...edge, isIncomplete: undefined }));
      });
    }
  );
