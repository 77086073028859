import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { Draft } from "immer";
import { DataType, EdgeId, isDtSubTypeWithEdges } from "sutro-common";

export const removeEdgesThatPointToDtThatDoesntExist = ({
  draftDataTypes,
  changeTracker,
}: {
  draftDataTypes: Draft<DataType[]>;
  changeTracker: ChangeTracker;
}): void => {
  for (const dt of draftDataTypes.filter(isDtSubTypeWithEdges)) {
    const edgesToRemove: EdgeId[] = [];
    // Note: we first gather the list to be removed, and then remove them in a 2nd pass, so we are not modifying dt.edges while iterating over it
    for (const edge of dt.edges) {
      if (
        edge.relatedDtId !== undefined &&
        draftDataTypes.some((dtB) => dtB.id === edge.relatedDtId) === false
      ) {
        edgesToRemove.push(edge.edgeId);
      }
    }
    dt.edges = dt.edges.filter(
      (edgeB) => edgesToRemove.includes(edgeB.edgeId) === false
    );
    if (edgesToRemove.length > 0) {
      changeTracker.trackChange(
        `Removed edges ${edgesToRemove.join(", and ")} from ${
          dt.name
        } because they referred to something that no longer existed`
      );
    }
  }
};
