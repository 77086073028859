import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { ContainerDataType } from "sutro-common";
import { EDGE_QUANTITY_TYPES } from "sutro-common/edges/data-type-edge";
import { getEdgeIdAndDirectionForEdge } from "sutro-common/edges/get-edge-id-and-direction-for-edge";
import { isDataTypeSomeEdge } from "sutro-common/edges/is-edge-some-edge";

import { setEdgeQuantity } from "../definition-updaters/index.js";
/**
 * Make sure SOME edges have required annotations.
 *
 * @todo  we should probably find a way to get rid of the need for this.
 *        It's only needed today because belongsTo fields didn't have these set
 */
export const ensureSomeEdgesHaveRequiredAnnotations = (
  dataType: ContainerDataType,
  changeTracker: ChangeTracker
): void => {
  for (const edge of dataType.edges.filter(
    (e) =>
      isDataTypeSomeEdge(e) &&
      (e.whoCanAddRemove === undefined || e.modifierCanAddRemove === undefined)
  )) {
    changeTracker.trackChange(
      `'${dataType.name}''s edge '${edge.fieldName}' was missing required fields, so set it to SOME again to add those fields`,
      { oldValue: JSON.parse(JSON.stringify(edge)) }
    );
    setEdgeQuantity({
      draftDt: dataType,
      edgeIdAndDirection: getEdgeIdAndDirectionForEdge(edge),
      newEdgeQuantity: EDGE_QUANTITY_TYPES.SOME,
    });
  }
};
