import { Definition, EFFECT_TYPES } from "sutro-common";

// Added temporarily in April 2022 to help detect definitions not yet migrated to the new format. Should be safe to remove in a couple weeks.
export const checkDefinitionForRecentChanges = ({ actions }: Definition) => {
  for (const action of actions) {
    for (const effect of action.effectInstructions) {
      switch (effect.effectType) {
        case EFFECT_TYPES.UPDATE:
        case EFFECT_TYPES.CHARGE_USER:
        case EFFECT_TYPES.CANCEL_STRIPE_SUBSCRIPTION:
        case EFFECT_TYPES.SET_UP_SUBSCRIPTION_PAYMENTS:
        case EFFECT_TYPES.DELETE:
          if (effect.variables === undefined) {
            alert(
              `Effect ${effect.effectType} has no variables! This means we have not correctly migrated this definition`
            );
          }
      }
    }
  }
};
