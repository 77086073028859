import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import {
  DataType,
  IdentityDataType,
  isDtAppRoot,
  UnionDataType,
} from "sutro-common";
import type { DataTypeEdge } from "sutro-common/edges/data-type-edge";
import {
  EDGE_QUANTITY_TYPES,
  MODIFIER_CAN_ADD_REMOVE_TYPES,
} from "sutro-common/edges/data-type-edge";
import { getEdgeIdAndDirectionForEdge } from "sutro-common/edges/get-edge-id-and-direction-for-edge";
import { isDataTypeSomeEdge } from "sutro-common/edges/is-edge-some-edge";

import { setEdgeQuantity } from "../definition-updaters/index.js";
// If something have SOME of anybodies stuff, that stuff must be edgeBelongsQuantity === SOME
export const ensureSomeEdgeBelongsQuantityIsRight = ({
  edge,
  dataType,
  containedDt,
  correspondingBelongsToEdge,
  changeTracker,
}: {
  edge: DataTypeEdge;
  dataType: Exclude<DataType, UnionDataType | IdentityDataType>;
  containedDt: Exclude<DataType, UnionDataType | IdentityDataType>;
  correspondingBelongsToEdge: DataTypeEdge;
  changeTracker: ChangeTracker;
}): void => {
  if (
    isDataTypeSomeEdge(edge) &&
    edge.modifierCanAddRemove?.type ===
      MODIFIER_CAN_ADD_REMOVE_TYPES.ANYBODYS &&
    isDataTypeSomeEdge(correspondingBelongsToEdge) === false &&
    isDtAppRoot(dataType) === false
  ) {
    setEdgeQuantity({
      draftDt: containedDt,
      edgeIdAndDirection: getEdgeIdAndDirectionForEdge(
        correspondingBelongsToEdge
      ),
      newEdgeQuantity: EDGE_QUANTITY_TYPES.SOME,
    });

    changeTracker.trackChange(
      `Set ${containedDt.name} belongsTo ${dataType.name} to SOME because ${dataType.name} has SOME ${containedDt.name} and they can be anybodys`
    );
  }
};
