import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import {
  DataType,
  Definition,
  EdgeId,
  getAppRootDt,
  getDt,
  isDtAppRoot,
  isDtUser,
} from "sutro-common";
import { APP_ROOT_DT_ID } from "sutro-common/dt-id";
import type { DataTypeSomeEdge } from "sutro-common/edges/data-type-edge";
import {
  MODIFIER_CAN_ADD_REMOVE_TYPES,
  WHO_CAN_ADD_REMOVE_TYPES,
  WHO_CAN_HAVE_TYPES,
} from "sutro-common/edges/data-type-edge";
import { isDataTypeSomeEdge } from "sutro-common/edges/is-edge-some-edge";

import { getDTByFollowingEdges } from "./get-dt-by-following-edges.js";
export const ensureAclsAreSetCorrectly = ({
  definition: { dataTypes },
  draftDataTypes,
  dataType,
  changeTracker,
}: {
  definition: Definition;
  draftDataTypes: DataType[];
  dataType: DataType;
  changeTracker: ChangeTracker;
}): void => {
  for (const edge of dataType.edges.filter(isDataTypeSomeEdge)) {
    const someEdge: DataTypeSomeEdge = edge;

    if (
      someEdge.whoCanHave?.type === WHO_CAN_HAVE_TYPES.LIST_MEMBERS_ONLY &&
      someEdge.whoCanHave.listFieldEdgeId !== ""
    ) {
      // follow edges and check the field is still there
      const containingDT = someEdge.whoCanHave.listIsOnAppRoot
        ? getAppRootDt(draftDataTypes)
        : getDTByFollowingEdges({
            fromDt: dataType,
            edges: someEdge.whoCanHave.edgesToListContainer ?? [],
            dts: draftDataTypes,
          });
      if (
        containingDT === undefined ||
        containingDT.edges.some(
          (edgeB) => edgeB.edgeId === someEdge.whoCanHave?.listFieldEdgeId
        ) === false
      ) {
        changeTracker.trackChange(
          `Set ${someEdge.fieldName} whoCanHave.listFieldEdgeId to -1`
        );
        someEdge.whoCanHave.listFieldEdgeId = "" as EdgeId;
      }
    }
    if (
      someEdge.whoCanAddRemove?.type ===
        WHO_CAN_ADD_REMOVE_TYPES.LIST_MEMBERS_ONLY &&
      someEdge.whoCanAddRemove.listFieldEdgeId !== ""
    ) {
      const containingDT = someEdge.whoCanAddRemove.listIsOnAppRoot
        ? draftDataTypes.find((dt) => dt.id === APP_ROOT_DT_ID)
        : getDTByFollowingEdges({
            fromDt: dataType,
            edges: someEdge.whoCanAddRemove.edgesToListContainer ?? [],
            dts: draftDataTypes,
          });
      if (
        containingDT === undefined ||
        containingDT.edges.some(
          (edgeB) => edgeB.edgeId === someEdge.whoCanAddRemove?.listFieldEdgeId
        ) === false
      ) {
        changeTracker.trackChange(
          `Set ${someEdge.fieldName} whoCanAddRemove.listFieldEdgeId to -1`
        );
        someEdge.whoCanAddRemove.listFieldEdgeId = "" as EdgeId;
      }
    }

    /** @todo:  move into a separate chunk of code that just runs heuristics, not actual invariants */
    // Heuristically ensure whoCanAddRemove is set correctly for comments
    if (
      someEdge.fieldName.toLowerCase() === "comments" &&
      dataType.name !== "User"
    ) {
      someEdge.whoCanAddRemove = {
        type: WHO_CAN_ADD_REMOVE_TYPES.ANYBODY,
      };
    }
  }

  for (const edge of dataType.edges
    .filter(isDataTypeSomeEdge)
    .filter((e) => e.relatedDtId !== undefined)) {
    const someEdge: DataTypeSomeEdge = edge;
    const containedDt = getDt(dataTypes, edge.relatedDtId);

    // We can heuristically set modifierCanAddRemove in this case based on whoCanAddRemove
    // I.e. if X has a list of users, it only makes sense to modify a list of other users, or to toggle yourself in and out of someone elses list (anybody adding anybody is silly, as is user only toggling self)
    if (isDtUser(containedDt) && isDtAppRoot(dataType) === false) {
      if (
        someEdge.whoCanAddRemove?.type === WHO_CAN_ADD_REMOVE_TYPES.ANYBODY &&
        someEdge.modifierCanAddRemove?.type !==
          MODIFIER_CAN_ADD_REMOVE_TYPES.OWN_ONLY
      ) {
        changeTracker.trackChange(
          "Set edge.modifierCanAddRemove.type to OWN_ONLY for edge",
          {
            someEdge,
          }
        );
        someEdge.modifierCanAddRemove = {
          ...someEdge.modifierCanAddRemove,
          type: MODIFIER_CAN_ADD_REMOVE_TYPES.OWN_ONLY,
        };
      } else if (
        someEdge.whoCanAddRemove?.type === WHO_CAN_ADD_REMOVE_TYPES.OWNER &&
        someEdge.modifierCanAddRemove?.type !==
          MODIFIER_CAN_ADD_REMOVE_TYPES.ANYBODYS
      ) {
        changeTracker.trackChange(
          "Set edge.modifierCanAddRemove.type to ANYBODYS for edge",
          {
            someEdge,
          }
        );
        someEdge.modifierCanAddRemove = {
          ...someEdge.modifierCanAddRemove,
          type: MODIFIER_CAN_ADD_REMOVE_TYPES.ANYBODYS,
        };
      }
    }
  }
};
