import colorConvert from "color-convert";
import { getRandom } from "sutro-common";
import { HexColor } from "sutro-common/hex-colors";

// Generates a random color in normal hsl range for app primary colors
export const randomAppColor = (): HexColor => {
  const hue = Math.round(getRandom() * 360); // 0 to 360
  const saturation = Math.round(getRandom() * 50) + 40; // 40 to 90
  const luminocity = Math.round(getRandom() * 50) + 40; // 40 to 90
  return `#${colorConvert.hsl.hex([hue, saturation, luminocity])}`;
};
