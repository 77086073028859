import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { Draft } from "immer";
import {
  byId,
  DataType,
  DT_TYPES,
  findDt,
  isDtActionInput,
  isDtAppRoot,
  isDtContainer,
  isDtUnion,
  isDtUser,
  SerializedAction,
} from "sutro-common";

export const removeDtsThatDontRelateToAContainerOrUnion = ({
  draftDataTypes,
  actions,
  changeTracker,
}: {
  draftDataTypes: Draft<DataType[]>;
  actions: Draft<SerializedAction[]>;
  changeTracker: ChangeTracker;
}): void => {
  /** @todo:  handle removing unions too */
  for (const dt of draftDataTypes
    .filter(isDtContainer)
    .filter((d) => d.type !== DT_TYPES.TRANSIENT_INPUT_CONTAINER)) {
    // Remove any DTs that don't belong to anything (unless they are User or AppRoot)
    const isActionInputForExistingAction =
      isDtActionInput(dt) &&
      actions.some((a) => a.actionId === dt.isActionInputForActionId);

    if (
      dt.edges.filter((edge) => {
        const relatedDt = findDt(byId)(draftDataTypes, edge.relatedDtId);
        return (
          relatedDt !== undefined &&
          (isDtContainer(relatedDt) || isDtUnion(relatedDt))
        );
      }).length === 0 &&
      isDtUser(dt) !== true &&
      isDtAppRoot(dt) !== true &&
      isActionInputForExistingAction !== true
    ) {
      changeTracker.trackChange(
        `Removed dt ${dt.name} from future validated DTs because it had no references`
      );

      const removeFromArrWithCondition = <A>(
        arr: A[],
        condition: (a: A, idx: number, coll: A[]) => boolean
      ) => {
        arr.splice(arr.findIndex(condition), 1);
      };
      removeFromArrWithCondition(draftDataTypes, (dtB) => dtB.id === dt.id);
    }
  }
};
