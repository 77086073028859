import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import {
  ContainerDataType,
  DataType,
  isDtActionInput,
  not,
  SerializedAction,
} from "sutro-common";

type Params = {
  dataTypes: DataType[];
  actions: SerializedAction[];
  changeTracker: ChangeTracker;
};

/**
 * If an action is marked incomplete, it will be filtered out of the validated definition.
 * Any action inputs that are connected to an incomplete action should also be filtered out.
 */
export const getDataTypesWithUnnecessaryActionInputsRemoved = ({
  dataTypes,
  actions,
  changeTracker,
}: Params) => {
  return dataTypes.filter((dt) => {
    if (not<DataType, ContainerDataType>(isDtActionInput)(dt)) {
      return true;
    }
    const actionInputForActionId = dt.isActionInputForActionId;
    const action = actions.find((a) => a.actionId === actionInputForActionId);
    if (action === undefined || action.isIncomplete === true) {
      changeTracker.trackChange(
        `Removed action input ${dt.name} (${
          dt.id
        }) because it is connected to ${
          action?.isIncomplete ? "incomplete" : "deleted"
        } action ${action?.name ?? "<DELETED>"} (${
          action?.actionId ?? "<DELETED>"
        })`
      );
      return false;
    }
    return true;
  });
};
