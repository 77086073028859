import {
  Definition,
  isDtPrimitive,
  jsonCopy,
  migrateDefinition,
  not,
} from "sutro-common";
import { primitiveDtsForInitializingDefinition } from "sutro-common/primitives-and-meta";
import { DEFAULT_THEME } from "sutro-common/theme-definition";
import { blankDefinition, defaultAppMeta } from "sutro-definitions";

import { randomAppColor } from "./random-app-color";

export const getNewDefinition = (): Definition => {
  const baseDefinition = migrateDefinition(blankDefinition);
  return jsonCopy({
    ...baseDefinition,
    dataTypes: [
      ...baseDefinition.dataTypes.filter(not(isDtPrimitive)),
      ...primitiveDtsForInitializingDefinition,
    ],
    appMeta: {
      ...defaultAppMeta,
      theme: {
        ...DEFAULT_THEME,
        app: {
          ...DEFAULT_THEME.app,
          primaryColor: {
            // Give every new app a random color
            value: randomAppColor(),
          },
        },
      },
    },
  });
};
