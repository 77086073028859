import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { getInverseFieldName } from "@sutro/studio2-quarantine/lib/dt-manipulation-helpers";
import { ContainerDataType, DataType } from "sutro-common";
import type { DataTypeEdge } from "sutro-common/edges/data-type-edge";
import { getReverseEdge } from "sutro-common/edges/get-reverse-edge";
import { isCreatorEdge } from "sutro-common/edges/is-creator-edge";
export const ensureGeneratedEdgeNameIsCorrect = ({
  changeTracker,
  dataType,
  draftDataTypes,
  edge,
}: {
  changeTracker: ChangeTracker;
  dataType: ContainerDataType;
  draftDataTypes: DataType[];
  edge: DataTypeEdge;
}): void => {
  if (edge?.pluginData?.namingMeta?.shouldUseGeneratedName === true) {
    const reverseEdge = getReverseEdge({
      edge,
      dataTypes: draftDataTypes,
    });
    const originalEdgeFromDt = draftDataTypes.find(
      (dt) => dt.id === edge.relatedDtId
    );
    if (!reverseEdge || !originalEdgeFromDt) {
      return;
    }

    const correctFieldName = getInverseFieldName({
      edgeId: edge.edgeId,
      fieldName: reverseEdge.fieldName,
      originalEdgeToDt: dataType,
      originalEdgeFromDt,
      dts: draftDataTypes,
      belongsDirectionQuantity: edge.quantity,
      isEdgeCreatorEdge: isCreatorEdge(edge),
    });
    if (edge.fieldName !== correctFieldName) {
      edge.fieldName = correctFieldName;
      changeTracker.trackChange(
        `Update reverse edge fieldname of ${reverseEdge.fieldName} to ${correctFieldName}`,
        {
          edge: reverseEdge,
          reverseEdge: edge,
        }
      );
    }
  }
};
