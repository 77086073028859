import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { Draft } from "immer";
import {
  DataType,
  DtId,
  EdgeId,
  isDataTypeEdgeHasEdge,
  isDtTransientInputContainer,
  isDtUnion,
} from "sutro-common";
import type { DataTypeEdge } from "sutro-common/edges/data-type-edge";
import { DATA_TYPE_EDGE_DIRECTION } from "sutro-common/edges/data-type-edge";

import { syncFeatureTemplateMetaBetweenEdges } from "../sync-feature-template-meta-between-edges.js";
import { createReverseEdge } from "@sutro/studio2-quarantine/definitions/definition-updaters/create-reverse-edge";
import { validateEdgePair } from "./validate-edge-pair.js";
export type EdgeInfo = {
  fromDtId: DtId;
  toDtId: DtId;
  unionEdge: boolean;
  edge: Draft<DataTypeEdge>;
};

/**
 * This function takes a list of data types and creates 2 maps:
 * - dtsById: A map of data type id to data type
 * - edgesByEdgeId: A map of edgeId to a list of edges with that edgeId
 *
 * @param draftDataTypes
 * @returns
 */
const getDtsAndEdgesById = (draftDataTypes: Draft<DataType>[]) => {
  const dtsById: Record<DtId, Draft<DataType>> = {};
  const edgesByEdgeId: Record<EdgeId, EdgeInfo[]> = {};

  draftDataTypes.forEach((dt) => {
    dtsById[dt.id] = dt;
    if (dt.edges !== undefined) {
      dt.edges.forEach((edge) => {
        edgesByEdgeId[edge.edgeId] = [
          ...(edgesByEdgeId[edge.edgeId] ?? []),
          { fromDtId: dt.id, toDtId: edge.relatedDtId, unionEdge: false, edge },
        ];
      });
    }
    if (isDtUnion(dt) && dt.containsUnionEdges !== undefined) {
      dt.containsUnionEdges.forEach((edge) => {
        edgesByEdgeId[edge.edgeId] = [
          ...(edgesByEdgeId[edge.edgeId] ?? []),
          { fromDtId: dt.id, toDtId: edge.relatedDtId, unionEdge: true, edge },
        ];
      });
    }
  });

  return { dtsById, edgesByEdgeId };
};

/**
 * This function takes a two-length array of EdgeInfos and checks if they are correctly related.
 *
 * @example DtA has an edge called EdgeA. Likewise, DtB -> EdgeB. If EdgeA and EdgeB are corresponding edges,
 * their `relatedDt` should also be correct. i.e. EdgeA's relatedDt should be equal to DtB's id,
 * and EdgeB's relatedDt should be equal to DtA's id.
 *
 * @param [edgeInfoA, edgeInfoB]
 * @returns boolean
 */
const areTwoEdgesSuccessfullyRelated = ([edgeInfoA, edgeInfoB]: EdgeInfo[]) =>
  edgeInfoA.fromDtId === edgeInfoB.toDtId &&
  edgeInfoA.toDtId === edgeInfoB.fromDtId;

// This does 3 main things:
// - It removes any edges from transient input containers that shouldn't be there
// - It adds reverse edges if they should
// - It picks a default fieldName for a reverse edge if it's missing one for some legacy reason
export const updateDtsToEnsureAllHasEdgesHaveValidCorrespondingEdges = ({
  draftDataTypes,
  changeTracker,
}: {
  draftDataTypes: Draft<DataType[]>;
  changeTracker: ChangeTracker;
}) => {
  const { dtsById, edgesByEdgeId } = getDtsAndEdgesById(draftDataTypes);

  Object.entries(edgesByEdgeId).forEach(([edgeId, edgeInfos]) => {
    // More than two edges with the same edgeId means there's a problem
    if (edgeInfos.length > 2) {
      throw new Error(`Edge ${edgeId} exists more than twice!`);
    }

    // Since we might be removing an edge from a DT here, we should also remove it from `edgeInfos`
    edgeInfos = edgeInfos.filter((edgeInfo) => {
      const { edge } = edgeInfo;
      const relatedDt = dtsById[edge.relatedDtId];
      if (isDtTransientInputContainer(relatedDt)) {
        // If this container has no has-edges, skip it
        if (
          relatedDt.edges === undefined ||
          relatedDt.edges.filter(isDataTypeEdgeHasEdge).length === 0
        ) {
          return true;
        }
        relatedDt.edges = relatedDt.edges.filter(
          (e) => e.edgeId !== edge.edgeId
        );
        changeTracker.trackChange(
          `Removed edge ${edge.edgeId} from DT ${relatedDt.id} because it was belonging to an input container`
        );
        // Drop this edge from edgeInfos
        return false;
      } else {
        return true;
      }
    });

    // Two edges means that we have both a hasEdge and a reverse edge
    if (edgeInfos.length === 2) {
      if (areTwoEdgesSuccessfullyRelated(edgeInfos) === false) {
        let toBeRemovedEdgeInfo: EdgeInfo | undefined;
        edgeInfos = edgeInfos.filter((item) => {
          if (item.edge.direction === DATA_TYPE_EDGE_DIRECTION.belongsTo) {
            toBeRemovedEdgeInfo = item;
            return false;
          }
          return true;
        });

        if (toBeRemovedEdgeInfo !== undefined) {
          const toBeRemovedEdgeIdx = dtsById[
            toBeRemovedEdgeInfo.fromDtId
          ].edges.findIndex(
            (edge) => edge.edgeId === toBeRemovedEdgeInfo?.edge.edgeId
          );

          dtsById[toBeRemovedEdgeInfo.fromDtId].edges.splice(
            toBeRemovedEdgeIdx,
            1
          );
        }
      } else {
        const edges = edgeInfos.map((edgeInfo) => edgeInfo.edge);

        const valid = validateEdgePair(edges, { dtsById, changeTracker });
        if (valid === false) {
          return;
        }
      }
    }
    if (edgeInfos.length === 1) {
      // We have a sole edge, so we need to create the reverse edge (in the general case, but not always)
      const edgeInfo = edgeInfos[0];
      const { edge, fromDtId } = edgeInfo;
      if (edge.relatedDtId === undefined) {
        return;
      }
      const relatedDt = dtsById[edge.relatedDtId];

      if (relatedDt === undefined) {
        throw new Error(
          `Could not find related DT ${edge.relatedDtId} for edge ${edge.edgeId}`
        );
      }

      const fromDt = dtsById[fromDtId];
      // If the edge that does exist eminates from a transient input container, the thing is points to shouldn't have a reverse edge
      if (isDtTransientInputContainer(fromDt) === false) {
        const reverseEdge = createReverseEdge({
          forEdge: edge,
          forToDt: relatedDt,
          forFromDt: fromDt,
          dataTypes: draftDataTypes,
        });

        relatedDt.edges = relatedDt.edges ?? [];
        relatedDt.edges.push(reverseEdge);

        changeTracker.trackChange(
          `Added a ${reverseEdge.direction} edge '${reverseEdge.fieldName}' from ${relatedDt.name} (id ${relatedDt.id}) to ${fromDt.name} (id ${fromDt.id}) because it was missing. New edge is edgeId ${edge.edgeId}`
        );

        syncFeatureTemplateMetaBetweenEdges({
          edgeA: edge,
          edgeB: reverseEdge,
        });
      }
    }
  });
};
