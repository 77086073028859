import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { Draft } from "immer";
import { DataType, DtId, EdgeId, isDtUnion } from "sutro-common";
import type { DataTypeEdge } from "sutro-common/edges/data-type-edge";
import { getReverseDirection } from "sutro-common/edges/get-reverse-direction";

/**
 * Logs two edges to the console; this is needed because the edges are actually Proxies, so they can't be logged directly
 * @param edgeA
 * @param edgeB
 */
const logEdges = (edgeA: DataTypeEdge, edgeB: DataTypeEdge) => {
  console.log({
    edgeA: { ...edgeA },
    edgeB: { ...edgeB },
  });
};

/**
 * Logs two data types to the console; this is needed because the DTs and their edges are actually Proxies,
 * so they can't be logged directly
 *
 * @param dtA
 * @param dtB
 */
const logDTs = (dtA: DataType, dtB: DataType) => {
  console.log({
    dtA: { ...dtA, edges: dtA.edges?.map((e) => ({ ...e })) },
    dtB: { ...dtB, edges: dtB.edges?.map((e) => ({ ...e })) },
  });
};

/**
 * This function confirms that an edge exists within a DT (either as a regular edge or as a union edge)
 * @param dt
 * @param edge
 * @returns
 */
const confirmEdgeBelongsToDt = (dt: DataType, edge: DataTypeEdge) =>
  dt.edges
    ?.map((e: Draft<{ edgeId?: EdgeId }>) => e.edgeId)
    ?.includes(edge.edgeId) ||
  (isDtUnion(dt) &&
    dt.containsUnionEdges
      ?.map((e: Draft<{ edgeId?: EdgeId }>) => e.edgeId)
      ?.includes(edge.edgeId));

export const validateEdgePair = (
  edges: DataTypeEdge[],
  {
    dtsById,
    changeTracker,
  }: { dtsById: Record<DtId, DataType>; changeTracker: ChangeTracker }
): boolean => {
  const [edgeA, edgeB]: DataTypeEdge[] = edges;
  const [dtRelatedToA, dtRelatedToB] = [
    edgeA.relatedDtId,
    edgeB.relatedDtId,
  ].map((dtId) => dtsById[dtId]);

  if (
    getReverseDirection(edgeA.direction) !== edgeB.direction ||
    getReverseDirection(edgeB.direction) !== edgeA.direction
  ) {
    logEdges(edgeA, edgeB);
    logDTs(dtRelatedToA, dtRelatedToB);
    // Temporarily disabled becuase Couro definition fails this check
    // changeTracker.fail(`Edge ${edgeA.edgeId} has mismatched directions!`, {
    //   edgeA,
    //   edgeB,
    //   dtRelatedToA,
    //   dtRelatedToB,
    // });
    return false;
  }

  if (confirmEdgeBelongsToDt(dtRelatedToA, edgeB) === false) {
    logEdges(edgeA, edgeB);
    logDTs(dtRelatedToA, dtRelatedToB);

    changeTracker.fail(
      `Edge "${edgeB.fieldName}" (${edgeB.edgeId}) cannot be found in DT ${dtRelatedToA?.id}`,
      { edgeA, edgeB, dtRelatedToA, dtRelatedToB }
    );
    return false;
  }
  if (confirmEdgeBelongsToDt(dtRelatedToB, edgeA) === false) {
    logEdges(edgeA, edgeB);
    logDTs(dtRelatedToA, dtRelatedToB);
    changeTracker.fail(
      `Edge "${edgeA.fieldName}" (${edgeA.edgeId}) cannot be found in DT ${dtRelatedToB?.id}`,
      { edgeA, edgeB, dtRelatedToA, dtRelatedToB }
    );
    return false;
  }
  return true;
};
