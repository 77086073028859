import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import {
  ContainerDataType,
  DataType,
  IdentityDataType,
  isDtAppRoot,
  isDtUser,
  UnionDataType,
} from "sutro-common";
import type { DataTypeEdge } from "sutro-common/edges/data-type-edge";
import { EDGE_QUANTITY_TYPES } from "sutro-common/edges/data-type-edge";
import { getEdgeIdAndDirectionForEdge } from "sutro-common/edges/get-edge-id-and-direction-for-edge";
import { isDataTypeSomeEdge } from "sutro-common/edges/is-edge-some-edge";

import { setEdgeQuantity } from "../definition-updaters/index.js";
// If you have some users, they must belong to some of you, unless you are app root
export const ensureIfYouHaveSomeUsersTheyBelongToSomeOfYouExceptAppRoot = ({
  edge,
  dataType,
  containedDt,
  correspondingBelongsToEdge,
  changeTracker,
}: {
  edge: DataTypeEdge;
  dataType: ContainerDataType;
  containedDt: Exclude<DataType, UnionDataType | IdentityDataType>;
  correspondingBelongsToEdge: DataTypeEdge;
  changeTracker: ChangeTracker;
}) => {
  if (
    isDtUser(containedDt) &&
    isDataTypeSomeEdge(edge) &&
    isDtAppRoot(dataType) !== true
  ) {
    if (isDataTypeSomeEdge(correspondingBelongsToEdge) === false) {
      setEdgeQuantity({
        draftDt: containedDt,
        edgeIdAndDirection: getEdgeIdAndDirectionForEdge(
          correspondingBelongsToEdge
        ),
        newEdgeQuantity: EDGE_QUANTITY_TYPES.SOME,
      });
      changeTracker.trackChange(
        `${dataType.name} contains SOME users so corresponding belongsTo edge on User to SOME ${dataType.name}`,
        {
          hasEdge: edge,
        }
      );
    }
  }
};
