import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { produce } from "immer";
import { Definition } from "sutro-common";

import { updateDefinitionForReceivingPayments } from "./marketplace-payments/update-definition-for-receiving-payments.js";
import { updateDefinitionToTagEdgesToReactions } from "./reactions/update-definition-to-tag-edges-to-reactions.js";
export const updateDefinitionByRunningPlugins = ({
  definition,
  changeTracker,
}: {
  definition: Definition;
  changeTracker: ChangeTracker;
}): Definition => {
  const definitionWithChanges = produce(definition, (draftDefinition) => {
    const pluginPayload = {
      draftDefinition,
      changeTracker,
    };

    updateDefinitionForReceivingPayments(pluginPayload);
    updateDefinitionToTagEdgesToReactions(pluginPayload);
  });
  return definitionWithChanges;
};
