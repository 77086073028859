import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import {
  ContainerDataType,
  DataType,
  DT_TYPES,
  IdentityDataType,
  isDtActionInput,
  UnionDataType,
} from "sutro-common";
import type { DataTypeEdge } from "sutro-common/edges/data-type-edge";
import { EDGE_QUANTITY_TYPES } from "sutro-common/edges/data-type-edge";
import { getEdgeIdAndDirectionForEdge } from "sutro-common/edges/get-edge-id-and-direction-for-edge";
import { isCreatorEdge } from "sutro-common/edges/is-creator-edge";
import { isEdgeSingleEdge } from "sutro-common/edges/is-edge-single-edge";
import { isDataTypeSomeEdge } from "sutro-common/edges/is-edge-some-edge";

import { setEdgeQuantity } from "../definition-updaters/index.js";
// If a DT has SINGLE other DT, that DT should belongsTo SOME this one.
export const ensureDtCorrespondingBelongsToEdgeQuantityIsCorrect = ({
  edge,
  dataType,
  containedDt,
  correspondingBelongsToEdge,
  changeTracker,
}: {
  edge: DataTypeEdge;
  dataType: ContainerDataType;
  containedDt: Exclude<DataType, UnionDataType | IdentityDataType>;
  correspondingBelongsToEdge: DataTypeEdge;
  changeTracker: ChangeTracker;
}): void => {
  if (
    isEdgeSingleEdge(edge) &&
    containedDt.type !== DT_TYPES.PRIMITIVE &&
    isDtActionInput(dataType) === false
  ) {
    if (correspondingBelongsToEdge === undefined) {
      console.log({ edge });
      debugger;
    }
    if (
      isDataTypeSomeEdge(correspondingBelongsToEdge) === false &&
      isCreatorEdge(correspondingBelongsToEdge) === false
    ) {
      setEdgeQuantity({
        draftDt: containedDt,
        edgeIdAndDirection: getEdgeIdAndDirectionForEdge(
          correspondingBelongsToEdge
        ),
        newEdgeQuantity: EDGE_QUANTITY_TYPES.SOME,
      });
      changeTracker.trackChange(
        `Set ${containedDt.name}'s edge ${correspondingBelongsToEdge.edgeId} belongsTo ${dataType.name} to quantity to SOME because ${dataType.name} only has SINGLE ${correspondingBelongsToEdge.edgeId}`
      );
    }
  }
};
