import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { DataType, isDtUnion, UnionDataType } from "sutro-common";

/*
 * A union is orphaned if:
 *   1. It is complete, and
 *   2. It has no edges
 */
const isOrphaned = (union: UnionDataType) =>
  union.isIncomplete !== true && union.edges.length === 0;

/**
 * Find all the orphaned Union DTs and remove them from the definition
 */
export const removeOrphanedUnionDts = ({
  draftDataTypes,
  changeTracker,
}: {
  draftDataTypes: DataType[];
  changeTracker: ChangeTracker;
}) => {
  const unions = draftDataTypes.filter(isDtUnion);

  const orphanedUnionIds = unions.filter(isOrphaned).map((dt) => dt.id);

  orphanedUnionIds.forEach((id) => {
    const dtIndex = draftDataTypes.findIndex((dtB) => dtB.id === id);
    if (dtIndex >= 0) {
      draftDataTypes.splice(dtIndex, 1);
      changeTracker.trackChange(`Removed orphaned union: ${id}`);
    }
  });
};
