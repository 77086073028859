import { Draft } from "immer";
import type { DataTypeEdge } from "sutro-common/edges/data-type-edge";

/**
 * Applies a patch to plugin data on an edge
 *
 * We should move to using applyPluginDataPatch from sutro-common
 * @deprecated
 */
export const applyPatchToEdgePluginData = (
  edge: Draft<DataTypeEdge>,
  patch: DataTypeEdge["pluginData"] = {}
) => {
  Object.assign(edge, {
    pluginData: {
      ...edge.pluginData,
      ...patch,
    },
  });
};
