import {
  getDefaultFieldInstructions,
  getFieldInstructionsByMergingOldAndDefault,
} from "@sutro/studio2-quarantine/lib/field-instruction-helpers";
import { produce } from "immer";
import {
  ContainerDataType,
  Definition,
  EFFECT_TYPES,
  getDt,
} from "sutro-common";

export const getUpdatedDefinitionBySynchronizingActionsAndDTs = (
  definition: Pick<Definition, "dataTypes" | "actions">
): Pick<Definition, "dataTypes" | "actions"> =>
  produce(
    definition,
    ({ dataTypes: draftDataTypes, actions: draftActions }) => {
      for (const draftAction of draftActions) {
        for (const draftEffect of draftAction.effectInstructions) {
          // If we're creating or editing a DT, ensure the fieldInstructions still match the current version of the DT.
          // Note: this is being crazy often right now for stuff that doesn't really change, so probably find a way to avoid it for efficiency
          // Also note there's probably a more elegant way to do this than to generate the default action again upon every edit, but it's not totally obvious to me right now, so let's do this
          if (
            draftEffect.effectType === EFFECT_TYPES.CREATE ||
            draftEffect.effectType === EFFECT_TYPES.UPDATE
          ) {
            const idOfDTToCreateOrUpdate =
              draftEffect.effectType === EFFECT_TYPES.CREATE
                ? draftEffect.idOfDTToCreate
                : draftEffect.idOfDTToUpdate;

            if (idOfDTToCreateOrUpdate !== "") {
              const draftDtToCreateOrUpdate = getDt(
                draftDataTypes,
                idOfDTToCreateOrUpdate
              ) as ContainerDataType;
              const defaultFieldInstructions = getDefaultFieldInstructions({
                draftDt: draftDtToCreateOrUpdate,
                draftDataTypes,
                effectType: draftEffect.effectType,
              });
              const newFieldInstructions =
                getFieldInstructionsByMergingOldAndDefault({
                  oldFieldInstructions: draftEffect.fieldInstructions || [],
                  defaultFieldInstructions,
                });
              draftEffect.fieldInstructions = newFieldInstructions;
            }
          }
        }
      }
    }
  );
