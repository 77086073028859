import { trace } from "@opentelemetry/api";
import {
  decorateDtAndActionModifiers,
  ModifiersDecorator,
} from "@sutro/studio2-quarantine/lib/decorate-dt-and-action-modifiers";
import getBundleForStudio, {
  BundleForStudioError,
} from "@sutro/studio2-quarantine/lib/get-bundle-for-studio";
import { useSaveState } from "@sutro/studio2-quarantine/lib/use-save-state";
import { Api, HttpResponse } from "@sutro/studio2-quarantine/sutro-api/Api";
import { SutroApi } from "@sutro/studio2-quarantine/sutro-api/index";
import { ActionModifiers } from "@sutro/studio2-quarantine/types/action-modifiers";
import { DtModifiers } from "@sutro/studio2-quarantine/types/dt-modifiers";
import { OnChangeAppMeta } from "@sutro/studio2-quarantine/types/on-change-app-meta";
import { SwitchDefinitionParams } from "@sutro/studio2-quarantine/types/switch-definition-params";
import { UpdateDefinition } from "@sutro/studio2-quarantine/types/update-definition";
import { UpdateDefinitionInStateAfterValidating } from "@sutro/studio2-quarantine/types/update-definition-in-state-after-validating";
import { UpdateDefinitionWithFeatureTemplateStatePatch } from "@sutro/studio2-quarantine/types/update-definition-with-feature-template-state-patch";
import { UpdateDefinitionWithPatch } from "@sutro/studio2-quarantine/types/update-definition-with-patch";
import _debounce from "debounce";
import { Draft, produce } from "immer";
import { toast } from "sonner";
import {
  $TSFixMe,
  $TSFixMeFunction,
  CURRENT_DEFINITION_VERSION,
  deepmergeAlt,
  Definition,
  GetBundleForStudioOptions,
  getRandom,
  isDtPrimitive,
  jsonCopy,
  KeyedUiConfigContainersForNodeChildren,
  UiConfigContainerForNodeAndDescendants,
} from "sutro-common";
import { SchemeConfigurationOptions } from "sutro-common/color-scheme";
import { InterpreterBundle } from "sutro-common/interpreter/bundle";
import { UnknownObject } from "sutro-common/object-types";
import { primitiveDtsForInitializingDefinition } from "sutro-common/primitives-and-meta";
import type { SLangParseResult } from "sutro-common/slang/compilation-outcome";
import { SerializableGeneratedTheme } from "sutro-common/theming/generated-theme";
import { defaultAppMeta } from "sutro-definitions";
import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";

import {
  getDefinitionWithFeatureTemplatePatchApplied,
  getUpdatedDefinitionAfterTransaction,
} from "~/lib/definitions/definition-updaters";
import { getPatchForUpdatingDefinitionInStateAfterValidating } from "~/lib/definitions/definition-updaters/get-patch-for-updating-definition-in-state-after-validating";
import { getUpdatedAndValidatedDefinition } from "~/lib/definitions/definition-validation";

import { getNewDefinition } from "../definitions/new-definition/get-new-definition";

/**
 * Takes a Definition setter and makes sure it only does an update
 * if there is a material change
 */
// const setDefinitionWith =
//   (
//     setter: Dispatch<SetStateAction<Definition>>
//   ): Dispatch<SetStateAction<Definition>> =>
//   (definitionOrFunction) => {
//     setter((prevDefinition: Definition) => {
//       const newDefinition =
//         typeof definitionOrFunction === "function"
//           ? definitionOrFunction(prevDefinition)
//           : definitionOrFunction;
//       const differences = microdiff(prevDefinition ?? {}, newDefinition);
//       if (
//         differences.length === 1 &&
//         differences[0].path.join(".") === "appMeta.lastEditedByCreator"
//       ) {
//         return prevDefinition;
//       }
//       console.debug("Differences", differences);
//       if (differences.length > 0) {
//         return newDefinition;
//       }
//       return prevDefinition;
//     });
//   };

export interface Studio {
  dtModifiers: DtModifiers;
  actionModifiers: ActionModifiers;
  slangParseResult: SLangParseResult | null;
  /**
   * The `workingDefinition` is what's updated in response to all Studio activity. It may or may not be
   * valid at any given point in time, but should generally be assumed to be invalid
   */
  workingDefinition: Definition;
  /**
   * The `validDefinition` is only every updated when the `workingDefinition` is valid. It is set to `null` to begin with.
   * When not null, it's what is used for saving and publishing
   */
  validDefinition: Definition | null;
  productionVersionDefinition: Definition | undefined;
  updateDefinitionWithPatch: UpdateDefinitionWithPatch;
  onChangeAppMeta: OnChangeAppMeta;
  updateDefinitionWithFeatureTemplateStatePatch: UpdateDefinitionWithFeatureTemplateStatePatch;
  updateDefinitionInStateAfterValidating: UpdateDefinitionInStateAfterValidating;
  updateDefinition: UpdateDefinition;
  generatedTheme: {
    darkTheme: SerializableGeneratedTheme;
    lightTheme: SerializableGeneratedTheme;
  } | null;
  setGeneratedTheme: (generatedTheme: {
    darkTheme: SerializableGeneratedTheme;
    lightTheme: SerializableGeneratedTheme;
  }) => void;
  interpreterBundle: InterpreterBundle | null;
  setInterpreterBundle: $TSFixMeFunction;
  switchDefinition: (params: SwitchDefinitionParams) => void;
  // isDisplaySmall: boolean;
  skipLogIn: boolean;
  setSkipLogIn: (skip: boolean) => void;
  updatePageContentsConfig: (state: {
    path: string[];
    newObjToMerge: Record<string, $TSFixMe>;
  }) => void;
  studioDefinitionLoading: boolean;
  setStudioDefinitionLoading: (newState: boolean) => void;
  studioDefinitionError: BundleForStudioError | null;
  fetchNewBundleForStudioAndUpdateState: (
    definition: Definition,
    options: GetBundleForStudioOptions
  ) => void;
  sessionId: string;
  previewSchemeSetting: SchemeConfigurationOptions;
  validateWorkingDefinition: () => void;
  setWorkingDefinitionAndValidate: (newWorkingDefinition: Definition) => void;
  executeSave: () => Promise<string | undefined>;
  inSupportMode: boolean;
}

export const useStudio = create<
  Studio & {
    /*
     * This is for injecting the API
     */
    authApi: Api;
    setAuthApi: (authApi: Api) => void;
  }
>()((set, get) => {
  /*
   * Shorthand function for setting the workingDefinition
   */
  const setWorkingDefinition = (newWorkingDefinition: Definition) =>
    set(() => ({ workingDefinition: newWorkingDefinition }));
  /*
   * Shorthand function for updating the workingDefinition
   */
  const updateWorkingDefinition = (
    updateFunction: (current: Definition) => { workingDefinition: Definition }
  ) => set((state) => updateFunction(state.workingDefinition));
  /*
   * Shorthand function for setting the validDefinition
   */
  const setValidDefinition = (newValidDefinition: Definition) =>
    set(() => ({ validDefinition: newValidDefinition }));

  const decorateCallbackForUpdatingDefinition: ModifiersDecorator =
    <A extends unknown[], R extends object | void>(
      fn: (draft: Draft<Definition>, ...args: A) => R
    ) =>
    (...params: A) => {
      const { workingDefinition } = get();
      let copiedResult;

      const newDefinition = produce(
        { ...workingDefinition },
        (draftDefinition) => {
          const rawResult = fn(draftDefinition, ...params);
          copiedResult =
            rawResult !== undefined ? jsonCopy(rawResult) : undefined;
        }
      );

      setWorkingDefinitionAndValidate(newDefinition);

      return copiedResult as R;
    };

  const validateWorkingDefinition = (causedByUserAction?: boolean) => {
    const { workingDefinition } = get();
    const { updatedDefinition, validatedDefinition, error, changeTracker } =
      getUpdatedAndValidatedDefinition({
        definition: workingDefinition,
      });

    if (changeTracker.logSize > 0) {
      changeTracker.showChanges("VALIDATION MADE CHANGES");
    }

    if (error !== undefined) {
      console.error("Error validating definition", error);
      return;
    }
    updateDefinitionInStateAfterValidating({
      newDefinition: updatedDefinition,
      causedByUserAction,
    });
    setValidDefinition(validatedDefinition);

    if (typeof window !== "undefined") {
      if (causedByUserAction) {
        const { skipLogIn, sessionId } = get();
        fetchNewBundleForStudioAndUpdateState(validatedDefinition, {
          skipLogIn,
          previewUser: "Admins",
          sessionId,
        });
      }
    }
  };

  const setWorkingDefinitionAndValidate = (
    newWorkingDefinition: Definition,
    causedByUserAction = true
  ) => {
    setWorkingDefinition(newWorkingDefinition);
    validateWorkingDefinition(causedByUserAction);
  };

  const updateDefinitionWithPatch: UpdateDefinitionWithPatch = (
    patch,
    causedByUserAction
  ) => {
    setWorkingDefinitionAndValidate(
      {
        ...get().workingDefinition,
        ...patch,
      },
      causedByUserAction
    );
  };

  const onChangeAppMeta: OnChangeAppMeta = (
    newMetaPatch,
    { switchingFullApp, causedByUserAction } = {}
  ) => {
    causedByUserAction = causedByUserAction ?? true;

    if (causedByUserAction) {
      // todo: capture analytics event SUT-2716
      // captureAnalyticsEvent(ANALYTICS_EVENT.UPDATE_DEFINITION, {
      //   type: "onChangeAppMeta",
      //   causedByUserAction,
      // });
    }

    if (causedByUserAction === true && switchingFullApp !== true) {
      // Note: can call onChangeAppMeta({}) if dts changed and just need to update this meta. This is pretty ugly but works for now.
      newMetaPatch[
        get().inSupportMode ? "lastEditedByAdmin" : "lastEditedByCreator"
      ] = Date.now();
    }

    if (switchingFullApp) {
      const newAppMeta = deepmergeAlt(
        { ...defaultAppMeta },
        {
          ...newMetaPatch,
          key: getRandom(),
        }
      );
      setWorkingDefinitionAndValidate(
        {
          ...get().workingDefinition,
          appMeta: newAppMeta,
        },
        causedByUserAction
      );
    } else {
      // Must do this instead of using appMeta, because reference to onChangeAppMeta passed into state machine may have stale references
      setWorkingDefinitionAndValidate(
        {
          ...get().workingDefinition,
          appMeta: deepmergeAlt(get().workingDefinition.appMeta, newMetaPatch),
        },
        causedByUserAction
      );
    }
  };

  const updateDefinitionWithFeatureTemplateStatePatch: UpdateDefinitionWithFeatureTemplateStatePatch =
    ({
      newFeatureTemplateState,
      newFrameworkMetaPatch,
      featureTemplateKey,
    }) => {
      const { workingDefinition } = get();

      const newDefinition = getDefinitionWithFeatureTemplatePatchApplied({
        prevDefinition: workingDefinition,
        newFeatureTemplateState,
        newFrameworkMetaPatch,
        featureTemplateKey,
      });

      setWorkingDefinitionAndValidate(newDefinition);
    };

  const updateDefinitionInStateAfterValidating: UpdateDefinitionInStateAfterValidating =
    ({ newDefinition, isInitializing = false, causedByUserAction = false }) => {
      const setUnsavedChanges = useSaveState.getState().setUnsavedChanges;

      let patch: ReturnType<
        typeof getPatchForUpdatingDefinitionInStateAfterValidating
      > = {
        patchForUpdatingDefinition: {},
        patchForValidDefinition: {},
      };
      try {
        patch = getPatchForUpdatingDefinitionInStateAfterValidating({
          newDefinition,
          isInitializing,
        });
      } catch (error) {
        // todo: capture analytics event SUT-2716
        // captureAnalyticsEvent(ANALYTICS_EVENT.VALIDATION_ERROR, {
        //   type: "updateDefinitionInStateAfterValidating",
        //   error,
        //   definition: newDefinition,
        //   isInitializing,
        //   causedByUserAction,
        // });
        return;
      }

      if (causedByUserAction) {
        setUnsavedChanges(true);
        // todo: capture analytics event SUT-2716
        // captureAnalyticsEvent(ANALYTICS_EVENT.UPDATE_DEFINITION, {
        //   type: "updateDefinitionInStateAfterValidating",
        //   causedByUserAction,
        // });
      }
      updateWorkingDefinition((currentWorkingDefinition) => ({
        workingDefinition: {
          ...currentWorkingDefinition,
          ...patch?.patchForUpdatingDefinition,
        },
      }));
    };

  // See readme.md in the definition updaters folder for more info about updateDefinition
  const updateDefinition: UpdateDefinition = (
    fnThatTakesUpdaters
  ): Definition => {
    const { workingDefinition } = get();

    const updatedDefinition = getUpdatedDefinitionAfterTransaction({
      definition: {
        ...workingDefinition,
        version: CURRENT_DEFINITION_VERSION,
      },
      fnThatTakesUpdaters,
    });

    updateDefinitionInStateAfterValidating({
      newDefinition: updatedDefinition,
      isInitializing: false,
    });

    return updatedDefinition;
  };

  const setInterpreterBundle = (
    interpreterBundle: InterpreterBundle | null
  ) => {
    set(() => ({
      interpreterBundle,
    }));
  };

  const switchDefinition = ({
    definition,
    causedByUserAction,
    productionVersionDefinition,
  }: SwitchDefinitionParams) => {
    // The primitives change over time and were (maybe unnecessarily) stored in the backend per-definition so may be stale, so we here remove all the saved primitives and add the latest ones
    const newDtsWithPrimitivesRemoved = definition.dataTypes.filter(
      (dt) => isDtPrimitive(dt) === false
    );
    const newDtsWithBuiltInPrimitives = [
      ...primitiveDtsForInitializingDefinition,
      ...newDtsWithPrimitivesRemoved,
    ];

    updateDefinitionInStateAfterValidating({
      newDefinition: {
        ...definition,
        dataTypes: newDtsWithBuiltInPrimitives,
        actions: definition.actions ?? [],
        uiAnnotations: definition.uiAnnotations ?? [],
      },
      isInitializing: true,
      causedByUserAction,
    });
    onChangeAppMeta(definition.appMeta, {
      switchingFullApp: true,
      causedByUserAction,
    });
    // Honestly, this makes no sense to me, since `definition` is typed as Definition
    // Leaving as is for now
    updateDefinitionWithPatch({
      pageContentsConfig: definition.pageContentsConfig,
    });
    set({ productionVersionDefinition });
  };

  const setSkipLogIn = (skipLogIn: boolean) => {
    set({ skipLogIn });
  };

  const updatePageContentsConfig = ({
    path,
    newObjToMerge,
  }: {
    path: string[];
    newObjToMerge: UnknownObject;
  }) => {
    const { workingDefinition } = get();

    // todo: capture analytics event SUT-2716
    // captureAnalyticsEvent(ANALYTICS_EVENT.UPDATE_DEFINITION, {
    //   type: "updatePageContentsConfig",
    // });

    updateDefinitionWithPatch({
      pageContentsConfig: produce(
        workingDefinition.pageContentsConfig,
        (draftPageContentsConfig) => {
          let objToUpdate:
            | KeyedUiConfigContainersForNodeChildren
            | UiConfigContainerForNodeAndDescendants = draftPageContentsConfig;
          for (const pathElem of path.slice(0, -1)) {
            //@ts-expect-error TS7053
            objToUpdate = objToUpdate[pathElem].contains;
          }
          //@ts-expect-error TS7053
          objToUpdate = objToUpdate[path[path.length - 1]];
          objToUpdate.configForCurrentNode = {
            ...objToUpdate.configForCurrentNode,
            ...newObjToMerge,
          };
        }
      ),
    });
  };

  const setStudioDefinitionLoading = (studioDefinitionLoading: boolean) => {
    set({ studioDefinitionLoading });
  };

  const setStudioDefinitionError = (
    studioDefinitionError: BundleForStudioError | null
  ) => {
    set({ studioDefinitionError });
  };

  const _refreshBundleForStudioDebounced = _debounce(
    (
      fetchFunction: (
        definition: Definition,
        options: GetBundleForStudioOptions
      ) => void,
      definition: Definition,
      options: GetBundleForStudioOptions
    ) => {
      fetchFunction(definition, options);
    },
    1000
  );

  const fetchNewBundleForStudioAndUpdateState: (
    definition: Definition,
    options: GetBundleForStudioOptions
  ) => void = (definition: Definition, options: GetBundleForStudioOptions) => {
    const _fetchFunction = async (
      _definition: Definition,
      _options: GetBundleForStudioOptions
    ) => {
      set({ slangParseResult: null });
      const tracer = trace.getTracer("sutro-studio");
      await tracer.startActiveSpan(
        "fetchNewBundleForStudioAndUpdateState",
        async (span) => {
          const { bundleForStudio, error: bundleError } =
            await getBundleForStudio({
              definition: _definition,
              options: _options,
            });

          if (bundleError) {
            span.recordException({
              name: "BundleForStudioError",
              message: bundleError.message,
              stack: new Error(bundleError.message).stack,
            });
            span.setAttribute("exception.escaped", false);
            setStudioDefinitionError(bundleError);
          } else {
            setStudioDefinitionError(null);
            set({ slangParseResult: bundleForStudio.slangParseResult });
            set(
              // Freeze to ensure we don't accidentally mutate it downstream
              (state) => {
                const currentCount =
                  state.interpreterBundle?.studioPreviewsCount ?? 0;
                return {
                  interpreterBundle: {
                    ...bundleForStudio.interpreterBundle,
                    // Every time we set the interpreter bundle, we increment a count in it
                    studioPreviewsCount: isNaN(currentCount)
                      ? 0
                      : currentCount + 1,
                  },
                };
              }
            );

            if (bundleForStudio?.studioMetaUpdate?.notifs) {
              onChangeAppMeta(
                { notifs: bundleForStudio?.studioMetaUpdate?.notifs },
                {
                  causedByUserAction: false,
                }
              );
            }

            if (bundleForStudio?.studioMetaUpdate?.pageContentsConfig) {
              updateDefinitionWithPatch(
                {
                  pageContentsConfig:
                    bundleForStudio.studioMetaUpdate.pageContentsConfig,
                },
                false
              );
            }
          }
          setStudioDefinitionLoading(false);
          span.end();
        }
      );
    };
    setStudioDefinitionLoading(true);
    _refreshBundleForStudioDebounced(_fetchFunction, definition, options);
  };

  const { dtModifiers, actionModifiers } = decorateDtAndActionModifiers(
    decorateCallbackForUpdatingDefinition,
    updateDefinitionInStateAfterValidating
  );

  const setGeneratedTheme = (theme: $TSFixMe) => {
    set({ generatedTheme: theme });
  };

  return {
    authApi: SutroApi.getApi(),
    setAuthApi: (authApi: Api) => {
      set({ authApi });
    },
    dtModifiers,
    actionModifiers,
    slangParseResult: null,
    workingDefinition: getNewDefinition(),
    validDefinition: null,
    productionVersionDefinition: undefined,
    onChangeAppMeta,
    updateDefinitionWithPatch,
    updateDefinitionWithFeatureTemplateStatePatch,
    updateDefinitionInStateAfterValidating,
    updateDefinition,
    generatedTheme: null,
    setGeneratedTheme,
    interpreterBundle: null,
    setInterpreterBundle,
    switchDefinition,
    skipLogIn: true,
    setSkipLogIn,
    updatePageContentsConfig,
    studioDefinitionLoading: false,
    setStudioDefinitionLoading,
    studioDefinitionError: null,
    setStudioDefinitionError,
    fetchNewBundleForStudioAndUpdateState,
    sessionId: uuidv4(),
    previewSchemeSetting: SchemeConfigurationOptions.AUTO,
    inSupportMode: false,
    executeSave: async () => {
      const validatedDefinition = get().validDefinition;
      const { setSaveInProgress, setUnsavedChanges } = useSaveState.getState();

      setSaveInProgress(true);
      try {
        let result: HttpResponse<unknown>;
        // const firstSave = false;
        let productId: string | undefined = validatedDefinition?.appMeta.id;
        if (productId === undefined) {
          // firstSave = true;
          result = await get().authApi.post(`/products`, {
            definition: JSON.stringify(validatedDefinition),
          });

          if (result.isLeft()) {
            throw result.getLeft();
          }

          result.ifRight((newProduct) => {
            // @ts-expect-error TS2339
            productId = newProduct.id;
            onChangeAppMeta({
              id: productId,
            });
          });
        } else {
          result = await get().authApi.put(`/products/${productId}`, {
            definition: JSON.stringify(validatedDefinition),
          });
        }

        setSaveInProgress(false);
        if (result.isLeft()) {
          result.ifLeft(() => {
            toast.error(
              "Something went wrong trying to save. Please try again later."
            );
          });
          setUnsavedChanges(true);
          return;
        } else {
          setUnsavedChanges(false);

          toast.success("Saved the changes to your app");
          return productId;
        }
      } catch (e) {
        console.error(e);
        toast.error(
          "Something went wrong trying to save. Please try again later.",
          { description: e instanceof Error ? e.message : String(e) }
        );
        throw e;
      }
    },
    validateWorkingDefinition,
    setWorkingDefinitionAndValidate,
  };
});
